<template>
  <Transition name="popr">
    <div v-if="visible" class="p-choose-group">
      <CommonHeader :back="close" head_title="@某人">
        <span
          slot="right"
          class="check-vote"
          @click="close"
        >
          确认
        </span>
      </CommonHeader>
      <SearchBarSimple v-model="keyword" placeholder="搜索用户" />
      <main id="searchUs" @scroll="scrollEvent">
        <JoLoadMoreFixed
          ref="loadmorefixed"
          @onRefresh="onSearchInput"
          @onLoadMore="onLoadMore"
        >
          <ul class="list">
            <li
              v-for="user in list"
              :key="user.id"
              @click="selectUser(user)"
            >
              <span v-if="checkChoose(user)" class="s-option active">
                <img
                  :src="require('@/images/multi_select_on@2x.png')"
                  class="m-svg-def"
                >
              </span>
              <span v-else class="s-option" />
              <UserItem :user="user" />
            </li>
          </ul>
        </JoLoadMoreFixed>
      </main>
    </div>
  </Transition>
</template>

<script>
  import SearchBarSimple from '@/components/common/SearchBarSimple.vue'
  import JoLoadMoreFixed from '@/components/JoLoadMoreFixed.vue'
  import UserItem from './UserItem.vue'

  export default {
    name: 'ChooseUsers',
    components: { UserItem, SearchBarSimple, JoLoadMoreFixed },
    props: {
      choose: { type: Array, required: false },
    },
    data () {
      return {
        visible: false,
        keyword: '',
        list: [],
        choseUsers: [],
      }
    },
    watch: {
      keyword (keyword) {
        this.onSearchInput()
      },
      choose (choose) {
        this.choseUsers = choose
      },
    },
    methods: {
      scrollEvent (event) {
        if (event.target.scrollTop + event.target.clientHeight + 200 >= event.target.scrollHeight) {
          this.$refs.loadmorefixed.beforeLoadMore()
        }
      },
      show () {
        this.visible = true
      },
      close () {
        this.$emit('change', this.choseUsers)

        this.visible = false
      },
      unique (arr) {
        // 创建一个Map对象实例
        const res = new Map()
        // 过滤条件：如果res中没有某个键，就设置这个键的值为1
        return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
      },
      checkChoose (user) {
        return this.choseUsers.findIndex((item) => { return item.id == user.id }) > -1
      },
      async onSearchInput () {
        // if (!this.keyword) return
        this.noResult = false
        const data = await this.search()
        this.list = data
        this.noResult = !this.list.length
        this.$refs.loadmorefixed.afterRefresh(data.length < 15)

        const event = this.$el.querySelector('#searchUs')
        event.scrollTop = 0
      },

      async onLoadMore () {
        // if (!this.keyword) return
        const data = await this.search(this.list.length)
        this.list.push(...data)
        this.$refs.loadmorefixed.afterLoadMore(data.length < 15)
      },
      search (offset) {
        const params = { name: this.keyword, offset: offset || 0 }
        return this.$store.dispatch('user/getUserList', params)
      },
      selectUser (user) {
        if (this.choseUsers.findIndex(item => { return user.id == item.id }) === -1) {
          this.addUser(user)
        } else {
          this.removeUser(user)
        }

        this.choseUsers = this.unique(this.choseUsers)
      },
      addUser (user) {
        const index = this.list.findIndex((item) => { return item.id == user.id })
        const obj = this.list[index]
        // if (obj.hasOwnProperty('selected') && obj.selected) return

        if (this.choseUsers.length >= 10) { return this.$Message.error('最多可选择10个用户') }

        this.$set(this.list[index], 'selected', true)
 
        this.choseUsers.push(obj)
      },
      removeUser (user) {
        const index = this.list.findIndex((item) => { return item.id == user.id })
        this.choseUsers.splice(this.choseUsers.findIndex((item) => { return item.id == user.id }), 1)
        this.$set(this.list[index], 'selected', false)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;

  .check-vote {
    color: @primary;
  }

  /deep/ .c-common-header {
    border: 0;
  }

  main {
    height: calc(100% - 1.8rem);
    overflow: auto;
  }

  .list {
    padding: 0 27px;

    > li {
      display: flex;
      border-bottom: 1px solid @border-color; /* no */

      .s-option {
        width: 32px;
        height: 32px;
        background: #E5E5E6;
        border-radius: 50%;
        margin: auto 0;

        > img {
          width: 32px;
          height: 32px;
          display: block;
          margin: auto;
          color: #fff;
          border-radius: 50%;
        }
      }

      // .s-option.active {
      //   background: #6085CC;
      // }
    }
    // > li + li {
    //   border-top: 1px solid @border-color; /* no */
    // }
  }

  .no-groups {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    > button {
      background-color: @primary;
      color: #fff;
      border-radius: 8px;
      padding: 15px 120px;
      font-size: 32px;
      margin-top: 30px;
    }
  }
}
</style>
