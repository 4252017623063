<template>
  <Transition name="popr">
    <div v-if="visible" class="p-choose-group">
      <CommonHeader :back="close" head_title="选择版块" />
      <div v-if="!list.length" class="no-groups">
        <p>未找到相关版块, 去加入感兴趣的版块吧～</p>
        <button @click="$router.replace({name: 'groups'})">加入版块</button>
      </div>
      <ul v-else class="list">
        <li
          v-for="group in list"
          :key="group.id"
          @click.capture.stop="selectGroup(group)"
        >
          <GroupItem :group="group" :choose="choose.id == group.id" />
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
  import GroupItem from './GroupItemNew.vue'

  export default {
    name: 'ChooseGroup',
    components: { GroupItem },
    props: {
      choose: { type: Object, required: false },
    },
    data () {
      return {
        visible: false,
        list: [],
      }
    },
    created () {
      this.fetchList()
    },
    methods: {
      show () {
        this.visible = true
      },
      close () {
        this.visible = false
      },
      async fetchList () {
        const list = await this.$store.dispatch('group/getMyGroups')
        this.list = list
        // 未选择则自动选中固定板块
        this.choose.id || this.defaultSelected()
      },
      defaultSelected() {
        for (let group of this.list) {
          if (group.non_delete === 1) {
            return this.selectGroup(group)
          }
        }
      },
      selectGroup (group) {
        this.$emit('change', group)
        this.close()
      },
    },
  }
</script>

<style lang="less" scoped>
.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #f4f5f5;

  .list {
    margin-top: 20px;

    > li + li {
      border-top: 1px solid @border-color; /* no */
    }
  }

  .no-groups {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    > button {
      background-color: @primary;
      color: #fff;
      border-radius: 8px;
      padding: 15px 120px;
      font-size: 32px;
      margin-top: 30px;
    }
  }
}
</style>
