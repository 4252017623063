<template>
  <div class="p-group-search">
    <SearchBar v-model="keyword" class="search-bar" />

    <NavTab :nav="nav" class="nav-tab" />

    <JoLoadMore
      ref="loadmore"
      :auto-load="false"
      :show-bottom="list.length > 0 "
      class="loadmore"
      @onRefresh="onSearchInput"
      @onLoadMore="onLoadMore"
    >
      <template v-if="type === 'posts'">
        <div v-if="keyword" class="post-list">
          <GroupFeedCard
            v-for="post in list"
            :key="post.id"
            class="post-card"
            :feed="post"
            :group="post.group"
          />
        </div>
      </template>

      <template v-else-if="type === 'groups'">
        <div v-if="keyword" class="group-list">
          <GroupItem
            v-for="group in list"
            :key="group.id"
            :group="group"
          />
        </div>
        <div v-else class="group-list">
          <GroupItem
            v-for="group in recommendList"
            :key="group.id"
            :group="group"
          />
        </div>
      </template>
    </JoLoadMore>

    <p v-show="loading" class="load-more-ph m-text-c mt10">正在搜索...</p>
    <template v-if="noResult && keyword && !list.length">
      <div v-if="type === 'posts'" class="no-groups">
        <p> 未找到相关动态～</p>
        <button @click="$router.push({name: 'groupCreatePost'})">去发帖</button>
      </div>
      <div v-else class="no-groups">
        <p> 未找到相关圈子, 创建属于自己的圈子吧</p>
        <button @click="$router.push({name: 'groupCreate'})">创建圈子</button>
      </div>
    </template>
  </div>
</template>

<script>
  import * as api from '@/api/group'
  import SearchBar from '@/components/common/SearchBar.vue'
  import NavTab from '@/components/common/NavTab.vue'
  import GroupItem from './components/GroupItem.vue'
  import GroupFeedCard from '@/components/FeedCard/GroupFeedCard.vue'
  import i18n from '@/i18n'

  const nav = [
    { name: 'groups', label: i18n.t('group.name'), route: { query: { type: 'groups' } } },
    { name: 'posts', label: i18n.t('group.post.name'), route: { query: { type: 'posts' } } },
  ]

  export default {
    name: 'SearchPost',
    components: {
      SearchBar,
      NavTab,
      GroupItem,
      GroupFeedCard,
    },
    data () {
      return {
        keyword: '',
        list: [],
        recommendList: [],
        loading: false,
        noResult: false,
        nav,
      }
    },
    computed: {
      type () {
        return this.$route.query.type || 'groups'
      },
    },
    watch: {
      keyword (val, oldVal) {
        if (val.trim() === '') return (this.list = [])
        if (val.trim() === oldVal.trim()) return
        this.$refs.loadmore.beforeRefresh()
      },
      type () {
        this.list = []
        if (this.keyword) {
          this.$refs.loadmore.beforeRefresh()
        } else {
          this.onSearchInput()
        }
      },
    },
    created () {
      // 如果不带参 默认搜索圈子
      if (!this.$route.query.type) this.$route.query.type = 'groups'
      // 如果搜索圈子, 没有数据时显示推荐的列表
      if (this.type === 'groups') this.fetchRecommendGroups()
    },
    methods: {
      async onSearchInput () {
        if (!this.keyword) return
        this.noResult = false
        const { data } = await this.search()
        this.list = data
        this.noResult = !this.list.length
        this.$refs.loadmore.afterRefresh(data.length < 15)
      },

      async onLoadMore () {
        if (!this.keyword) return
        const { data } = await this.search(this.list.length)
        this.list.push(...data)
        this.$refs.loadmore.afterLoadMore(data.length < 15)
      },

      search (offset) {
        const params = { keyword: this.keyword, offset }
        return this.type === 'groups'
          ? api.searchGroups(params)
          : api.searchPosts(params)
      },

      async fetchRecommendGroups () {
        const data = await this.$store.dispatch('group/getGroups', {
          type: 'recommend',
        })
        this.recommendList = data
        this.$refs.loadmore.afterRefresh(true)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-group-search {
  height: 100%;

  .loadmore {
    padding-top: 90+90px;
    height: 100%;
  }

  .post-list {
    .post-card {
      margin-bottom: 10px;
    }
  }

  .search-bar {
    position: fixed;
  }
  .nav-tab {
    position: fixed;
    top: 90px;
  }
  .m-no-find {
    position: fixed;
    top: 0;
    width: 100%;
  }
  .no-groups {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;

    > button {
      background-color: @primary;
      color: #fff;
      border-radius: 8px;
      padding: 15px 120px;
      font-size: 32px;
      margin-top: 30px;
    }
  }
}
</style>
