<template>
  <ArticleCard
    ref="article"
    type="feed"
    :article="feedId"
    :liked="liked"
    :collected="has_collect"
    :comment-count="commentCount"
    :loading="loading"
    :scroll-hide-foot="false"
    @like="likePost"
    @collect="collectPost"
    @comment="$refs.comments.open()"
    @reward="afterReward"
  >
    <CommonHeader
      slot="head"
      more
      @more="moreAction"
    >
      动态详情
      <!-- <Avatar :user="user" size="tiny" />
      <span class="m-text-cut m-flex-none username">
        {{ user.name }}
      </span>
      <template
        v-if="!isMine"
        slot="right"
        :class="{ primary: relation.status !== 'unFollow' }"
      >
        <svg class="m-style-svg m-svg-def" @click="followUserByStatus(relation.status)">
          <use :xlink:href="relation.icon" />
        </svg>
      </template> -->
    </CommonHeader>

    <!-- 内容 -->
    <div id="waterCon" slot="main">
      <div class="addbox">
        <div v-if="feed.pinned_at" class="top paddinglr20">
          <img :src="require('@/images/top@2x.png')" alt="">
          <span>置顶</span>
        </div>
        <header class="m-box m-aln-center m-justify-bet m-card-usr paddinglr20">
          <Avatar
            :size="'small'"
            :user="user"
            :anonymity="anonymous"
            :is_anonymous="is_anonymous"
            :old_name="feed.username"
            :check="true"
          />
          <!-- 这里绑定头衔 -->
          <h4 class="m-flex-grow1 m-flex-shrink1 marginl10">
            <span
              v-if="!is_anonymous"
              @click="viewUser"
            >{{ userName }}<UserTitle
              v-if="!anonymous"
              :user="user"
            /></span>
            <span v-else>匿名用户</span>
          </h4>
          <!--          <routerLink-->
          <!--            tag="h4"-->
          <!--            class="m-flex-grow1 m-flex-shrink1 marginl10"-->
          <!--            :to="`/users/${user.id}`"-->
          <!--            onclick="nameEq"-->
          <!--          >-->
          <!--            {{ userName }}<UserTitle :user="user" v-if="!anonymous"/>-->
          <!--          </routerLink>-->
          <!--          <div
            v-if="!isMine && !anonymous"
            class="m-box m-aln-center"
            @click="startSingleChat"
          >
            <span class="talkBtn">私信</span>
          </div>-->
        </header>
        <!-- <article class="m-card-body" @click="handleView('')"> -->
        <h2 class="feed-title paddinglr20">
          <img
            v-if="feed.hot"
            :src="require('@/images/label_hot@2x.png')"
            class="feed-title-hot"
          >
          <img
            v-if="feed.excellent_at"
            :src="require('@/images/label_essence@2x.png')"
            class="feed-title-essence"
          >
          <span :class="{ tag: post.is_activity > 0 }">{{ title }}</span>
          <img
            v-if="feed.lock"
            :src="require('@/images/label_lock@2x.png')"
            class="feed-title-lock"
          >
        </h2>
        <div class="subtitle paddinglr20">
          <img
            v-if="post.v_come"
            :src="require('@/images/print_v@2x.png')"
            class="feed-title-sign"
          >
          <span v-if="time">{{
            time | time2tips | t("article.posted_at")
          }}</span>
          <span>{{ viewCount | t("article.views_count") }}</span>
        </div>
      </div>
      <main class="m-flex-shrink1 m-flex-grow1 m-art m-main">
        <div class="m-art-body">
          <p class="m-text-box m-text-pre" v-html="formatBody(feedContent)" />
          <video
            v-if="video.resource"
            ref="video"
            :poster="video.cover && video.cover.url"
            class="feed-detail-video"
            controls
            autoplay
            x5-video-player-type="h5"
            webkit-playsinline="true"
            playsinline="true"
          >
            <source :src="video.resource.url" type="video/mp4">
          </video>
          <div v-for="img in images" :key="img.id">
            <AsyncFile
              v-if="img.id"
              :file="img.id"
              :detail="true"
            >
              <img
                v-if="props.src"
                slot-scope="props"
                :src="props.src"
                @click="onFileClick($event, img)"
              >
            </AsyncFile>
          </div>
          <ul v-if="topics.length" class="topics">
            <li
              v-for="topic in topics"
              :key="topic.id"
              class="topic-item"
              @click.capture.stop="viewTopic(topic.id)"
              v-text="topic.name"
            />
          </ul>
          <div v-if="at.length > 0" class="aboutTitle">
            <routerLink
              v-for="u in at"
              :key="u.id"
              tag="span"
              :to="`/users/${u.id}`"
            >
              @{{ $getUserName(u) }}
            </routerLink>
          </div>
          <div v-if="at_department.length > 0" class="aboutTitle">
            <span v-for="u in at_department" :key="u.id">
              @{{ $getUserName(u) }}
            </span>
          </div>
        </div>
        <!-- 投票组件 -->
        <ShowVote
          v-if="votes"
          :vote="votes"
          :is_vote="is_vote"
          @checkVote="checkVote"
        />
        <!-- 点赞组件 -->
        <PostLike
          :likers="likes"
          :like-count="likeCount"
          :liked="liked"
          @checkLike="likePost"
        />
      </main>
      <!-- 圈子信息,可抽离组件 -->
      <div
        v-if="group"
        class="topic-card"
        @click="viewGroup"
      >
        <img :src="groupAvatar" class="topic-card-logo">
        <div class="topic-card-content">
          <div>{{ group.name }}</div>
          <div>{{ group.posts_count | t("group.post.count") }}</div>
        </div>
        <div class="topic-card-right">
          <svg class="m-style-svg m-svg-def entry__item--append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </div>

      <div>
        <!-- 评论列表 -->
        <ArticleComments
          ref="comments"
          type="post"
          :article="postId"
          :artical-title="title"
          :total.sync="commentCount"
          :fetching="fetchComing"
          :is_anonymous="is_anonymous"
          :author-id="user.id"
          @reply="replyComment"
        />
      </div>
    </div>
  </ArticleCard>
</template>

<script>
  import { mapState } from 'vuex'
  import { escapeHTML, markdownText } from '@/filters.js'
  import wechatShare from '@/util/wechatShare.js'
  import * as userApi from '@/api/user.js'
  import FeedDetail from '@/page/feed/FeedDetail.vue'
  import ArticleCard from '@/page/article/ArticleCard'
  import PostLike from '../components/PostLike'
  import ArticleComments from '@/page/article/components/ArticleComments'
  import ShowVote from '../components/ShowVote'
  import * as chatApi from '@/api/chat.js'

  import markdownIt from 'markdown-it'
  import plusImagePlugin from 'markdown-it-plus-image'

  import * as api from '@/api/group.js'
  import warterMark from '@/util/watermark.js'

  export default {
    name: 'PostDetail',
    components: {
      ArticleCard,
      PostLike,
      ArticleComments,
      ShowVote,
    },
    data () {
      return {
        feed: {
          likes: [],
        },
        oldId: NaN,
        loading: true,
        fetching: false,

        rewardCount: 0,
        rewardAmount: 0,
        rewardList: [],

        fetchComing: false,
        noMoreCom: false,
        maxComId: 0,
        user: {},
        at: [],
        at_department: [],
        votes: {},
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      post () {
        return this.feed
      },
      allowReward () {
        return this.$store.state.CONFIG.feed.reward
      },
      postId () {
        return Number(this.$route.params.postId)
      },
      groupId () {
        return Number(this.$route.params.groupId)
      },
      group () {
        return this.feed.group || {}
      },
      groupAvatar () {
        if (this.group.avatar) {
          return this.group.avatar.url
        }

        if (this.groupId < 5) {
          return require(`@/images/section_${this.groupId}@2x.png`)
        }

        return require('@/images/img_default_nothing@2x.png')
      },
      anonymous () {
        return this.feed.anonymous == 1
      },
      is_anonymous () {
        return this.feed.is_anonymous === 1
      },
      userName () {
        const name = this.$getUserNameNew(this.user, this.anonymous, this.feed)
        return name
      },
      pinned () {
        return this.feed.pinned_at != null
      },

      reward () {
        return {
          count: this.rewardCount,
          amount: this.rewardAmount,
          list: this.rewardList,
        }
      },
      feedId () {
        return Number(this.$route.params.feedId)
      },
      video () {
        return this.feed.video || { resource: null, cover: null }
      },
      title () {
        return this.feed.title
      },
      isMine () {
        return this.feed.user_id === this.CURRENTUSER.id
      },
      topics () {
        return this.feed.topics || []
      },
      likes: {
        get () {
          return this.feed.likes || []
        },
        set (val) {
          this.feed.likes = val
        },
      },
      liked: {
        get () {
          return !!this.feed.liked
        },
        set (val) {
          this.feed.liked = val
        },
      },
      likeCount: {
        get () {
          return this.feed.likes_count || 0
        },
        set (val) {
          this.feed.likes_count = ~~val
        },
      },
      commentCount: {
        get () {
          return this.feed.comments_count || 0
        },
        set (val) {
          this.feed.comments_count += val
        },
      },
      viewCount () {
        return this.feed.views_count || 0
      },
      firstImage () {
        const images = this.images
        if (!images.length) {
          return `${process.env.VUE_APP_API_HOST}/default_icon.jpg`
        }
        const file = images[0] || {}
        return this.$http.defaults.baseURL + '/files/' + file.id + '?w=300&h=300'
      },
      images () {
        return this.feed.images || []
      },
      time () {
        return this.feed.dead_line || ''
      },
      feedContent () {
        const { body = '' } = this.feed
        return body || ''
      },
      isWechat () {
        return this.$store.state.BROWSER.isWechat
      },
      isManager () {
        return this.CURRENTUSER.permission.is_admin || false
      },
      has_collect: {
        get () {
          return this.feed.collected
        },
        set (val) {
          this.feed.collected = val
        },
      },
      is_vote: {
        get () {
          return this.feed.is_vote
        },
        set (val) {
          this.feed.is_vote = val
        },
      },
      relation: {
        get () {
          const relations = {
            unFollow: {
              text: this.$t('follow.name'),
              status: 'unFollow',
              icon: '#icon-unFollow',
            },
            follow: {
              text: this.$t('follow.already'),
              status: 'follow',
              icon: '#icon-follow',
            },
            eachFollow: {
              text: this.$t('follow.each'),
              status: 'eachFollow',
              icon: '#icon-eachFollow',
            },
          }
          const { follower, following } = this.user
          const relation =
            follower && following
              ? 'eachFollow'
              : follower
                ? 'follow'
                : 'unFollow'
          return relations[relation]
        },

        set (val) {
          this.user.follower = val
        },
      },
    },
    beforeMount () {
    // if (this.isIosWechat) {
    //   this.reload(this.$router);
    // }
    },
    mounted () {
      this.setWarter()
    },
    created () {
      if (this.postId) {
        this.feed = {}
        this.fetchFeed()
      }
    },
    deactivated () {
    // this.loading = true;
    // this.fetching = false;
    // this.share = {
    //   title: "",
    //   desc: "",
    //   link: ""
    // };
    // this.config = {
    //   appid: "",
    //   timestamp: 0,
    //   noncestr: "",
    //   signature: ""
    // };
    },
    destroyed () {
      warterMark.out()
    },
    methods: {
      formatBody (body) {
        const emotionReg = new RegExp(
          /\/assets\/pc\/ueditor\/dialogs\/emotion\/images\//,
        )

        body = body.replace(
          /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
          function (match, capture) {
            if (!emotionReg.test(capture)) {
              return `<img src="${capture}" onClick="showBig('${capture}')"/>`
            // return `<img src="${capture}" onClick=" let src = this.src; let app = document.getElementById('app'); let imgBox = document.createElement('div'); imgBox.className = 'img-box'; app.appendChild(imgBox); imgBox.innerHTML = '<div><img src=\'+src+\'></div>'; imgBox.addEventListener('click',function(iv){imgBox.remove()});"/>`
            } else {
              return match
            }
          },
        )

        return body
      },
      startSingleChat () {
        chatApi.createRoom(this.user.id).then(({ data }) => {
          this.$nextTick(() => {
            this.$router.push({ name: 'ChatRoom', params: { chatId: data.id } })
          })
        })
      },
      fetchFeed () {
        if (this.fetching) return
        this.fetching = true
        const shareUrl = this.$getUrl()
        const signUrl =
          this.$store.state.BROWSER.OS === 'IOS' ? window.initUrl : shareUrl
        api.getGroupPost(this.groupId, this.postId).then(({ data }) => {
          this.fetching = false
          this.loading = false
          this.feed = { ...data }
          this.user = this.feed.user
          this.at = this.feed.at
          this.at_department = this.feed.at_department
          this.votes = this.feed.votes
          this.oldId = this.postId
          this.fetchPostComments()
          this.fetchLikes()

          // this.$matomoTrackPageView(`${this.title} - 旭辉员工论坛（SPA）`)
          console.log(
            this.feed.video
              ? `${process.env.VUE_APP_API_HOST}/default_icon_video.jpg`
              : this.firstImage,
          )
          if (this.isWechat) {
            document.title =
              data.title.length > 10
                ? `${data.title.substr(0, 10)}...`
                : data.title
            let desc = markdownText(data.body) || ''
            desc = desc.length > 20 ? `${desc.substr(0, 20)}...` : desc
            wechatShare(signUrl, {
              title: data.title,
              desc: desc,
              link: shareUrl,
              imgUrl: this.feed.video
                ? `${process.env.VUE_APP_API_HOST}/default_icon_video.jpg`
                : this.firstImage,
            })
          }
        })
      },
      fetchUserInfo () {
        userApi.getUserInfoById(this.feed.user_id, true).then((user) => {
          this.user = Object.assign({}, this.user, user)
        })
      },
      setWarter () {
        let name = this.CURRENTUSER.name
        if (this.CURRENTUSER.email) {
          name = this.CURRENTUSER.email.substr(
            0,
            this.CURRENTUSER.email.indexOf('@'),
          )
        }
        warterMark.set(name)
      },
      fetchPostComments () {
        // 解决未挂载组件前进行获取实列失败
        this.$nextTick(() => this.$refs.comments.fetch())
      },
      fetchLikes () {
        this.$http
          .get(
            `/plus-group/group-posts/${this.postId}/likes`,
            {
              params: { limit: 8 },
            },
            {
              validateStatus: (s) => s === 200,
            },
          )
          .then(({ data = [] }) => {
            // this.feed.likes = data;
            this.feed = { ...this.feed, ...{ likes: data } }
          })
      },
      viewTopic (topicId) {
        this.$router.push({ name: 'TopicDetail', params: { topicId } })
      },
      afterReward (amount) {
        this.fetchRewards()
        this.rewardCount += 1
        this.rewardAmount += amount
      },
      likePost () {
        api
          .likeGroupPost(this.postId, this.liked)
          .then(() => {
            if (this.liked) {
              this.liked = false
              this.likeCount -= 1
              this.feed.likes = this.feed.likes.filter((like) => {
                return like.user_id !== this.CURRENTUSER.id
              })
              this.$bus.$emit('updatePost', this.postId, 'unlike')
            } else {
              this.liked = true
              this.likeCount += 1
              if (this.feed.likes.length < 5) {
                this.feed.likes.push({
                  user: this.CURRENTUSER,
                  id: new Date().getTime(),
                  user_id: this.CURRENTUSER.id,
                })
              }
              this.$bus.$emit('updatePost', this.postId, 'like')
            }
          })
          .finally(() => {
            this.fetching = false
          })
      },
      moreAction () {
        const actions = []
        if (this.isManager) {
          if (this.feed.lock) {
            actions.push({
              text: '解锁动态',
              method: () => {
                const actions = [
                  {
                    text: '解锁动态',
                    method: () => {
                      api.unLockPost(this.postId).then(() => {
                        this.$Message.success('解锁成功')
                        this.fetchFeed()
                      })
                    },
                  },
                ]
                setTimeout(() => {
                  this.$bus.$emit(
                    'actionSheet',
                    actions,
                    '取消',
                    '确认解锁动态?',
                  )
                }, 200)
              },
            })
          } else {
            actions.push({
              text: '锁定',
              method: () => {
                api.lockPost(this.postId).then(() => {
                  this.$Message.success('锁定成功！')
                  this.fetchFeed()
                })
              },
            })
          }
          if (this.feed.excellent_at) {
            actions.push({
              text: '取消精华',
              method: () => {
                const actions = [
                  {
                    text: '取消精华',
                    method: () => {
                      api.unExcellentPost(this.postId).then(() => {
                        this.$Message.success('取消精华成功！')
                        this.fetchFeed()
                      })
                    },
                  },
                ]
                setTimeout(() => {
                  this.$bus.$emit(
                    'actionSheet',
                    actions,
                    '取消',
                    '确认取消精华?',
                  )
                }, 200)
              },
            })
          } else {
            actions.push({
              text: '设为精华',
              method: () => {
                api.excellentPost(this.postId).then(() => {
                  this.$Message.success('设置精华成功！')
                  this.fetchFeed()
                })
              },
            })
          }
          if (!this.pinned) {
            actions.push({
              text: '置顶',
              method: () => {
                this.$bus.$emit('applyTop', {
                  type: 'post-manager',
                  api: api.pinnedPost,
                  payload: this.feed.id,
                  callback: () => {
                    this.$Message.success('置顶成功！')
                    this.fetchFeed()
                  },
                })
              },
            })
          } else {
            actions.push({
              text: '撤销置顶',
              method: () => {
                const actions = [
                  {
                    text: '撤销置顶',
                    method: () => {
                      this.$store
                        .dispatch('group/unpinnedPost', {
                          postId: this.feed.id,
                        })
                        .then(() => {
                          this.$Message.success('撤销置顶成功！')
                          this.fetchFeed()
                        })
                    },
                  },
                ]
                setTimeout(() => {
                  this.$bus.$emit(
                    'actionSheet',
                    actions,
                    '取消',
                    '确认撤销置顶?',
                  )
                }, 200)
              },
            })
          }
          actions.push({
            text: '移动',
            method: () => {
              if (this.$refs.video) {
                this.$refs.video.pause()
              }

              this.$bus.$emit('MovePost', {
                api: api.movePost,
                payload: this.feed.id,
                callback: () => {
                  this.$Message.success('移动成功！')
                  this.fetchFeed()
                },
              })
            },
          })
        }
        if (!this.isMine && !this.isManager) {
          actions.push({
            text: '举报',
            method: () => {
              if (this.$refs.video) {
                this.$refs.video.pause()
              }
              this.$bus.$emit('report', {
                type: 'post',
                payload: this.feed.id,
                username:
                  this.feed.is_anonymous === 1 ? '匿名用户' : this.userName,
                reference: this.title,
              })
            },
          })
        }
        if (this.isMine || this.isManager) {
          actions.push({
            text: '删除',
            method: () => {
              this.$bus.$emit('popupConfirm', {
                content: '确认删除动态吗？',
                onClose: () => {},
                onConfirm: () => {
                  this.$store
                    .dispatch('group/deletePost', {
                      groupId: this.groupId,
                      postId: this.postId,
                    })
                    .then(() => {
                      this.$Message.success('删除动态成功')
                      this.goBack()
                    })
                },
                confirmText: '确认',
                cancelText: '取消',
              })
            },
          })
        }
        this.$bus.$emit('actionSheet', actions, '取消')
      },
      replyComment (comment) {
        const actions = []
        if (this.isManager) {
          if (comment.excellent_at) {
            actions.push({
              text: '取消置顶',
              method: () =>
                this.$refs.comments.unExcellentComment(comment.id, comment),
            })
          } else {
            // 设置置顶
            actions.push({
              text: '设为置顶',
              method: () =>
                this.$refs.comments.excellentComment(comment.id, comment),
            })
          }
        }
        // 是否是自己的评论
        if (comment.user_id === this.CURRENTUSER.id) {
          actions.push({
            text: this.$t('reply.name'),
            method: () => this.$refs.comments.open(comment.user, comment),
          })
          // 是否是自己文章的评论
          const isOwner = comment.user_id === this.user.id
          actions.push({
            text: '删除',
            method: () => this.$refs.comments.delete(comment.id, comment),
          })
        } else {
          actions.push({
            text: this.$t('reply.name'),
            method: () => this.$refs.comments.open(comment.user, comment),
          })
          if (this.isManager) {
            // 管理员
            // 删除评论
            actions.push({
              text: '删除',
              method: () => this.$refs.comments.delete(comment.id, comment),
            })
          } else {
            actions.push({
              text: this.$t('report.name'),
              method: () => {
                this.$bus.$emit('report', {
                  type: 'postComment',
                  payload: comment.id,
                  username:
                    comment.anonymous === 1 ? '匿名用户' : comment.user.name,
                  reference: comment.body,
                })
              },
            })
          }
        }
        this.$bus.$emit('actionSheet', actions)
      },
      followUserByStatus (status) {
        if (!status || this.fetchFollow) return
        this.fetchFollow = true

        userApi
          .followUserByStatus({
            id: this.user.id,
            status,
          })
          .then((follower) => {
            this.relation = follower
            this.user.extra.followers_count = follower
              ? this.user.extra.followers_count + 1
              : this.user.extra.followers_count - 1
            this.$store.commit('SAVE_USER', this.user)
            this.fetchFollow = false
            this.user.extra.followers_count = follower
              ? this.user.extra.followers_count + 1
              : this.user.extra.followers_count - 1
            this.$store.commit('SAVE_USER', this.user)
          })
      },
      onRefresh () {
        this.fetchFeed()
      },
      onFileClick ($event, img) {
        const component = this.$parent
        const src = `${this.$http.defaults.baseURL}/files/${img.id}?detail=true`
        const index = 0
        const el = $event.target
        const [w, h] = img.size.split('x')
        const images = [
          {
            ...img,
            w,
            h,
            el,
            src,
            index,
          },
        ]
        // const images = this.images.map((img, index) => {
        //   const el = $event.target
        //   const src = `${this.$http.defaults.baseURL}/files/${img.id}`
        //   const [w, h] = img.size.split('x')
        //   img.w = parseInt(w)
        //   img.h = parseInt(h)
        //   return {
        //     ...img,
        //     el,
        //     src,
        //     index,
        //   }
        // })

        console.log(images)

        this.$bus.$emit('mvGallery', { component, index, images })
      },
      checkVote (options) {
        const data = options.map((item) => item.id)

        api
          .postVote(this.postId, { option_id: data })
          .then((res) => {
            this.$Message.success('投票成功')
            this.votes.count++
            this.is_vote = true
          })
          .finally(() => {
            this.fetching = false
          })
      },
      viewGroup () {
        this.$router.push(`/groups/${this.group.id}`)
      },
      collectPost () {
        if (this.has_collect) {
          api.uncollectPost(this.postId).then((status) => {
            if (status) {
              this.$Message.popupSuccess('已取消收藏')
              this.has_collect = false
            }
          })
        } else {
          api.collectionPost(this.postId).then((status) => {
            if (status) {
              this.$Message.popupSuccess('收藏成功')
              this.has_collect = true
            }
          })
        }
      },
      viewUser () {
        if (this.anonymous) {
          return
        }
        const userId = this.user.id
        this.$router.push({ name: 'UserDetail', params: { userId } })
      },
    },
  }
</script>

<style lang="less" scoped>
.c-common-header {
  border: 0;

  /deep/ .left {
    padding: 0 0.7em 0 0.5em;
  }
}
.feed-detail-video {
  height: 100vw;
  width: 100vw;
  margin-left: -20px;
  background: #000;
}
.m-art {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.username {
  font-size: 0.32rem;
  margin-left: 0.1rem;
  text-align: center;
}

/deep/ .m-text-box {
  margin-top: auto;

  video.edui-upload-video {
    max-width: 100%;
    max-height: 100vw;
  }

  blockquote {
    padding: 0 20px;
    color: #6a737d;
    border-left: 10px solid #dfe2e5;
  }

  ol,
  ul {
    padding-left: 40px;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.875em;
  }
  h6 {
    font-size: 0.85em;
  }
}

/deep/ .m-art-body img {
  margin-left: 0;
  width: unset;
  max-width: 100%;
}

/deep/ .m-art-body p {
  margin-top: 0;
}

.topics {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0;

  .topic-item {
    padding: 0 16px;
    border-radius: 6px;
    background-color: #f7f7f7;
    font-size: 24px;
    color: #666666;
    margin-right: 12px;
    margin-bottom: 12px;
    cursor: pointer;
  }
}
.m-card {
  &-usr {
    font-size: 24px;
    color: #ccc;
    margin-bottom: 30px;

    h4 {
      color: #000;
      font-size: 26px;
    }

    span + span {
      margin-left: 15px;
    }
  }

  &-main {
    width: 100%;
  }

  &-con {
    overflow: hidden;
    font-size: 30px;
    line-height: 42px;
    color: @text-color2;
    display: -webkit-box;
    margin-bottom: 20px;

    .needPay:after {
      content: " 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情 付费节点，购买后方可查看原文详情";
      text-shadow: 0 0 10px @text-color2; /* no */
      color: rgba(255, 255, 255, 0);
      margin-left: 5px;
      // filter: DXImageTransform.Microsoft.Blur(pixelradius=2);
      zoom: 1;
    }
  }

  &-body {
    > h2 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  &-foot {
    .liked {
      color: @error;
    }
  }

  &-tools {
    padding: 20px 0;
    color: #b3b3b3;
    font-size: 24px;

    a {
      color: inherit;

      // + a {
      //   margin-left: 60px;
      // }
    }

    span {
      margin-left: 10px;
    }
  }

  &-comments {
    margin-bottom: 30px;
    line-height: 42px;
    color: @text-color3;
    font-size: 26px;
  }
}

.m-router-link {
  a {
    color: inherit;
  }

  font-size: 26px;
  color: @text-color1;
  margin-top: -15px;
  margin-bottom: 30px;
}
.marginl10 {
  margin-left: 10px;
}
.sign {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
.feed-title {
  position: relative;
  display: block;
  align-items: center;
  flex-wrap: wrap;
  color: #1a1a1a;
  font-size: 40px;
  .tag {
    &::before {
      content: "奖";
      display: inline-block;
      width: 0.35rem;
      height: 0.35rem;
      line-height: 0.35rem;
      font-size: 0.2rem;
      color: #fff;
      background: #e94824;
      border-radius: 0.04rem;
      text-align: center;
      position: relative;
      bottom: 0.08rem;
      margin-right: 0.04rem;
    }
  }
  &-sign {
    position: absolute;
    right: 0;
    top: -80px;
    width: 160px;
    height: 160px;
  }
  &-vip {
    right: 130px;
  }
  &-hot {
    width: 24px;
    height: 30px;
    margin-right: 10px;
  }
  &-essence {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &-lock {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
  }
  span {
    word-break: break-all;
  }
}
.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: @primary;
  font-size: 26px;
  img {
    width: 34px;
    height: 34px;
    margin: 0 10px;
  }
}
.paddinglr20 {
  padding: 0 20px;
}
.subtitle {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px 0 0 0;
  color: #999999;
  font-size: 24px;
  span + span {
    margin-left: 20px;
  }
}
.addbox {
  background-color: #fff;
  padding: 30px 0;
}
.talkBtn {
  padding: 6px 23px;
  color: #ffffff;
  font-size: 24px;
  background-color: @primary;
}
.aboutTitle {
  color: #5186eb;
  font-size: 30px;
  font-weight: 400;
  margin: 10px 0;
  span {
    margin: 10px;
  }
}
.topic-card {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 20px 0;
  padding: 20px 30px;
  background-color: #fff;
  &-logo {
    width: 100px;
    height: 100px;
    margin-right: 24px;
  }
  &-content {
    color: #999999;
    font-size: 24px;
    & > div:nth-child(1) {
      color: #000;
      font-size: 30px;
      font-weight: 600;
    }
  }
  &-right {
    margin-left: auto;
  }
}
/deep/ .m-art-comments {
  background: unset;
}
</style>
<style lang="less">
.pswp__button--close {
  display: none !important;
}
</style>
