<template>
  <ul class="list">
    <li v-for="item in list" :key="item.id" @click.stop="onCollapse(item)">
      <div class="title">
        <span class="t-icon" @click.stop="onChoose(item)">
          <img :src="require(`@/images/multi_select${ checkChoose(item) ? '_on' : '' }@2x.png`)" class="m-svg-def"/>
        </span>
        <span class="t-data">{{ item.name }}</span>
        <svg v-if="item.children && item.children.length" class="m-style-svg m-svg-def m-entry-append arrow" :class="collapse[item.id] && 'active'">
          <use xlink:href="#icon-arrow-right"/>
        </svg>
      </div>
      <div class="children collapse" :class="collapse[item.id] && 'active'" v-if="item.children && item.children.length">
        <DepartmentList
          :list="item.children"
          :choose="choose"
          :collapse="collapse"
          @onChoose="onChoose"
          @onCollapse="onCollapse" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "DepartmentList",
  props: {
    list: {
      type: Array,
      default: []
    },
    choose: {
      type: Array,
      default: []
    },
    collapse: {
      type: Object,
      default: {}
    },
  },
  methods: {
    // 是否选中
    checkChoose(data) {
      return this.choose.some(item => item.id === data.id);
    },
    // 选项选择
    onChoose(data) {
      this.$emit('onChoose', data)
    },
    // 展开收起
    onCollapse(data) {
      this.$emit('onCollapse', data)
    },
  }
}
</script>
