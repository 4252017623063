<template>
  <Transition name="popr">
    <div v-if="visible" class="p-choose-group">
      <div class="box-back" @click="close">
        <div class="back">
          <div><md-icon name="arrow-left" /></div>
          <p>返回</p>
        </div>
        <h3>选择阅读奖励类型</h3>
        <div />
      </div>
      <div class="box-main">
        <md-radio-list
          v-model="isactivity"
          :options="activeConfig"
          icon="right"
          icon-inverse=""
          icon-disabled=""
          icon-position="right"
        />
      </div>
    </div>
  </Transition>
</template>

<script>
  import { Field, RadioList } from 'mand-mobile'
  import lstore from '@/plugins/lstore/'

  export default {
    name: '',
    components: { [Field.name]: Field, [RadioList.name]: RadioList },
    props: {
      activeType: {
        type: [Number, String],
      },
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        isactivity: 0,
      }
    },
    computed: {
      activeConfig () {
        const user = lstore.getData('H5_CUR_USER')
        const newuser = user.activity_conf.map((v) => {
          return {
            value: v.type,
            text: v.title,
          }
        })
        return newuser
      },
    },
    watch: {
      activeType: {
        deep: true,
        handler (value) {
          this.isactivity = value
        },
      },
    },
    created () {},
    mounted () {},
    methods: {
      close () {
        this.$emit('close', this.isactivity)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #f4f5f5;
  .box-back {
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back {
      display: flex;
      align-items: center;
      text-align: left;
      p {
        font-size: 28px;
      }
    }
  }
  .box-main {
    background: #fff;
    min-height: 320px;
    margin-top: 20px;
    padding: 0 20px;
  }
}
</style>
