<template>
  <section class="form-select-item" @click="switchTags">
    <label>{{ label }} <span>{{ required ? '(必填)': '(选填)' }}</span></label>
    <div class="input-wrap">
      <span class="placeholder">
        <span
          v-for="(tag, index) in value"
          :key="tag.id"
        >{{ tag.name }}<font v-if="(value.length - 1) > index">、</font></span>
      </span>
      <svg class="m-style-svg m-svg-def m-entry-append">
        <use xlink:href="#icon-arrow-right" />
      </svg>
    </div>
  </section>
</template>

<script>
  import i18n from '@/i18n'

  export default {
    name: 'ChooseTags',
    props: {
      value: { type: Array, default: () => [] },
      label: { type: String, default: i18n.t('tags.name') },
      readonly: { type: Boolean, default: false },
      placeholder: { type: String, default: i18n.t('tags.select') },
      required: { type: Boolean, default: false },
      type: { type: String, default: 'content' },
    },
    methods: {
      switchTags () {
        if (this.readonly) return
        const chooseTags = this.value.map(t => t.id)
        const nextStep = tags => {
          this.$emit('input', tags)
        }
        const onSelect = tagId => {
          this.$emit('select', tagId)
        }
        const onRemove = tagId => {
          this.$emit('delete', tagId)
        }
        this.$bus.$emit('choose-' + this.type + '-tags', {
          chooseTags,
          nextStep,
          onSelect,
          onRemove,
        })
      },
    },
  }
</script>

<style lang="less" scoped>
@import url("~@/components/form/formItem.less");
.form-select-item {

  label > span {
    color: #B2B2B2;
  }

  .input-wrap {
    svg {
      vertical-align: middle;
    }
  }

  .placeholder {
    margin-right: 20px;
    color: #999;
    font-size: 24px;

    .m-tag {
      margin: 0 0 0 10px;
      border-radius: 32px;
    }
  }
}
</style>
