<template>
  <Transition name="popr">
    <div v-if="visible" class="p-success">
      <CommonHeader :back="goBack" />
      <div class="no-groups">
        <img
          :src="require('@/images/succeed@2x.png')"
        >
        <h4>发布成功</h4>
        <p v-if="publish == 2 && publish_time" class="publish-notice">
          你的帖⼦子将在 <span>{{ publish_time }}</span> 准时发布
        </p>

        <div class="f-but">
          <button @click="goBack">返回</button>
          <button @click="goDetail">查看</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
  import GroupItem from './GroupItemNew.vue'

  export default {
    name: 'PostSuccess',
    components: { GroupItem },
    props: {
      group_id: { type: Number, required: true },
      post_id: { type: Number, required: true },
      publish: { type: Number, required: false, default: 1 },
      publish_time: { type: String, required: false },
    },
    data () {
      return {
        visible: false,
        list: [],
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      close () {
        this.visible = false
      },
      goDetail () {
        return this.$router.push(`/groups/${this.group_id}/posts/${this.post_id}`)
      },
    },
  }
</script>

<style lang="less" scoped>
.p-success {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;

  .c-common-header {
    border: 0;
  }

  .no-groups {
    padding-top: 180px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #999;

    > img {
      width: 222px;
      margin-bottom: 40px;
    }

    > h4 {
      font-size: 30px;
      color: #333333;
    }

    .publish-notice {
      font-size: 20px;
      color: #333;
      margin-top: 10px;

      > span {
        color: #FA9F14;
      }
    }

    .f-but {
      display: flex;
      margin-top: 68px;

      > button {
        border: 1px solid #E5E5E5;
        background: #fff;
        border-radius: 3px;
        padding: 8px 30px;
        font-size: 25px;
      }
      > button:first-child {
        margin-right: 45px;
      }
    }
  }
}
</style>
