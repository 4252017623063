<template>
  <Transition name="popr">
    <div v-if="visible" class="vote-box">
      <CommonHeader :back="close" head_title="添加投票">
        <span
          slot="right"
          :class="{ disabled }"
          class="check-vote"
          @click="checkVote"
        >
          确认
        </span>
      </CommonHeader>
      <div class="vote-con">
        <main>
          <div class="title-wrap">
            <input
              v-model="title"
              type="text"
              placeholder="投票名称，20字以内（必填）"
              maxlength="20"
            >
          </div>

          <div class="p-flex">
            <FormCalendar
              v-model="s_time"
              _name="s_time"
              label="投票开始时间（必填）"
              type="date"
              :min-date="now"
              @click="setStartTime"
            />
            <FormCalendar
              v-model="e_time"
              default-class="caladar-right"
              _name="e_time"
              label="投票结束时间（必填）"
              type="date"
              :min-date="now"
              @click="setEndTime"
            />
          </div>

          <div class="radio-wrap">
            <label>
              <input
                v-model="type"
                name="cur_education_1"
                type="radio"
                class="input-checkbox"
                :value="1"
              >
              <span>单选</span>
            </label>
            <label>
              <input
                v-model="type"
                name="cur_education_1"
                type="radio"
                class="input-checkbox"
                :value="2"
              >
              <span>多选</span>
            </label>
          </div>
        </main>

        <div class="vote-options">
          <div
            v-for="(item, index) in option"
            v-if="item && item.index > -1"
            :key="index"
          >
            <div class="v-tit">
              {{ `投票选项${toChinesNum(item.index + 1)}` }}
              <span v-if="index > 1" @click="deleteOption(index)">删除选项</span>
            </div>
            <div class="v-content">
              <textarea
                v-model="item.content"
                placeholder="选项描述，50字以内（必填）"
                maxlength="50"
                rows="3"
                :index="index"
                :sindex="item.index"
              />
              <VoteOptionImg
                :ref="`voteImg${index}`"
                :limit="1"
                :index="index"
                :initial_pics="item.pics"
                @changeImage="changeImage"
              />
            </div>
          </div>
        </div>
        <div class="v-tit" @click="addOption">+ 继续添加选项</div>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VoteOption from './VoteOption.vue'
  import VoteOptionImg from './VoteOptionImg'

  export default {
    name: 'CreateVote',
    components: { VoteOption, VoteOptionImg },
    props: {
      vote: { type: Object, required: false },
    },
    data () {
      return {
        visible: false,
        title: '',
        s_time: null,
        e_time: null,
        type: 1,
        option: [{ index: 0 }, { index: 1 }],
      }
    },
    // created () {
    //   this.title = this.vote.hasOwnProperty('title') ? this.vote.title : ''
    //   this.s_time = this.vote.s_time || null
    //   this.e_time = this.vote.e_time || null
    //   this.type = this.vote.type || 1
    //   this.option = this.vote.option || [{}, {}]
    // },
    computed: {
      ...mapGetters(['composePhoto']),
      disabled () {
        if (!this.title || !this.s_time || !this.e_time || !this.type) return true
        if (this.option.some(item => {
          return item && !item.content
        })) return true

        return false
      },
      now () {
        return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
      },
    },
    watch: {
      vote (vote) {
        this.title = this.vote.hasOwnProperty('title') ? this.vote.title : ''
        const time_s = this.vote.s_time.replace(/-/g, '/')
        const time_e = this.vote.e_time.replace(/-/g, '/')
        if (time_s) {
          const s_date = new Date(time_s)
          this.s_time = s_date.getFullYear() + '-' + (s_date.getMonth() + 1) + '-' + s_date.getDate()
        }
        if (time_e) {
          const e_date = new Date(time_e)
          this.e_time = e_date.getFullYear() + '-' + (e_date.getMonth() + 1) + '-' + e_date.getDate()
        }
        this.type = this.vote.type || 1

        if (this.vote.option) {
          let i = 0
          this.option = this.vote.option.map(item => {
            if (item) {
              item.index = i
              i++
            } else {
              item.index = -1
            }
            return item
          })
        }
      },
      composePhoto () {
        // console.log(this.composePhoto)
      },
    },
    methods: {
      show () {
        this.visible = true
      },
      close () {
        this.visible = false
      },
      setStartTime (time) {
        this.s_time = time
      },
      setEndTime (time) {
        this.e_time = time
      },
      toChinesNum (num) {
        const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
        const unit = ['', '十', '百', '千', '万']
        num = parseInt(num)
        const getWan = (temp) => {
          const strArr = temp.toString().split('').reverse()
          let newNum = ''
          for (var i = 0; i < strArr.length; i++) {
            newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
          }
          return newNum
        }
        const overWan = Math.floor(num / 10000)
        let noWan = num % 10000
        if (noWan.toString().length < 4) noWan = '0' + noWan

        return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
      },
      changeImage (index, images) {
        if (images && images.length > 0) {
          this.option[index].images = images[0].id
          this.option[index].pics = [
            {
              id: this.option[index].images,
              src: `${this.$http.defaults.baseURL}/files/${this.option[index].images}`,
              file: null,
              loading: false,
              error: false,
            },
          ]
        }
      },
      addOption () {
        let index = 0
        this.option.map(item => {
          if (item && item.index > -1) {
            index = item.index
          }
        })

        this.option.push({ index: ++index })
      },
      deleteOption (index) {
        if (this.option[index].images) {
          this.$refs[`voteImg${index}`][0].remove(index)
        }
        // delete this.option[index]

        this.$set(this.option, index, null)

        // let aa = this.option.filter((item, k) => {
        //   return index != k
        // })

        // console.log(this.option, aa)

        // console.log('ooo', this.option.filter((item, k) => {
        //   return index == k
        // }))
      },
      checkVote () {
        if (this.disabled) return false

        const param = {
          title: this.title,
          s_time: this.s_time,
          e_time: this.e_time,
          type: this.type,
          option: this.option,
        }

        if (param.title.length > 20) {
          this.$Message.info('投票名称不能超过20字')
          return false
        }
        if (new Date(param.s_time) > new Date(param.e_time)) {
          this.$Message.info('投票开始时间不能大于结束时间')
          return false
        }
        param.option = param.option.filter(item => item)
        if (param.option.some(item => item.content.length > 50)) {
          this.$Message.info('选项描述不能超过50字')
          return false
        }

        this.visible = false
        this.$emit('onVoteChange', param)
      },
      changeContent (event) {
        const index = event.target.attributes.index.value
        const content = event.target.value

        console.log(event.target.value, event.target.attributes.index.value)
      },
    },
  }
</script>

<style lang="less" scoped>
.vote-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #fff;

  .check-vote {
    color: @primary;
  }

  .check-vote.disabled {
    color: #ccc;
  }

  .vote-con {
    height: calc(100% - 90px);
    overflow: auto;
    padding-bottom: 100px;

    > main {
      padding: 30px;
      margin-bottom: 20px;
      background-color: #fff;

      .title-wrap {
        padding-bottom: 15px;
        border-bottom: 2px solid @border-color;

        input {
          font-size: 32px;
          width: 100%;
        }
      }

      .p-flex {
        display: flex;

        .caladar-right {
          position: relative;
          padding-left: 50px;

        }

        .caladar-right::before {
          content: "";
          width: 1px;
          display: block;
          background: #E5E5E5;
          left: 0;
          top: 30px;
          bottom: 30px;
          position: absolute;
        }
      }

      .radio-wrap {
        padding: 30px 0;
        margin-bottom: 20px;
        background-color: #fff;
        display: flex;

        label {
          display: block;
          margin-right: 100px;
          color: #B2B2B2;

          input {
            margin-left: 0;
          }
        }

      }
    }

    .vote-options {
      .v-tit {
        background: #F7F7F7;
        height: 80px;
        line-height: 80px;
        padding: 0 30px;
        font-size: 24px;

        > span {
          color: #5186EB;
          float: right;
        }
      }
      .v-content {
        padding: 30px;
        display: flex;

        textarea {
          width: calc(100% - 1.2rem);
          display: block;
          line-height: 40px;
          resize: none;
        }
      }
    }

    > .v-tit {
      background: #F7F7F7;
      height: 80px;
      line-height: 80px;
      padding: 0 30px;
      color: #5186EB;
      font-size: 24px;
    }
  }
}
</style>
