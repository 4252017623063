<template>
  <div :class="`m-form-row m-main ${defaultClass}`">
    <span>{{ label }}</span>
    <div class="m-input">
      <input
        :id="_name"
        v-model="cur_value"
        class="right"
        type="text"
        :placeholder="placeholder"
        autocomplete="off"
        readonly="readonly"
      >
    </div>
  </div>
</template>

<script>
  import vueIosDatePicker from 'vue-ios-datepicker'
  import i18n from '@/i18n'

  export default {
    name: 'FormCalendar',
    components: {
      vueIosDatePicker,
    },
    props: {
      _name: { type: String, default: 'date' },
      value: { type: String, default: '' },
      label: { type: String, default: i18n.t('date.name') },
      placeholder: { type: String, default: i18n.t('date.name') },
      type: { type: String, default: 'date' },
      minDate: { type: String, default: '' },
      maxDate: { type: String, default: '' },
      defaultClass: { type: String, default: '' },
    },
    data () {
      return {
        cur_value: this.value,
      }
    },
    mounted () {
      const self = this
      const calendar = new vueIosDatePicker()
      calendar.init({
        trigger: '#' + this._name, /* 按钮选择器，用于触发弹出插件 */
        type: this.type, /* 模式：date日期；datetime日期时间；time时间；ym年月； */
        minDate: this.minDate,
        maxDate: this.maxDate,
        onSubmit: function () { /* 确认时触发事件 */
          self.switchDate(calendar.value)
        },
        onClose: function () { /* 取消时触发事件 */
        },
      })
    },
    methods: {
      switchDate: function (value) {
        this.$emit('click', value)
      },
    },
  }
</script>
<style lang="less">
@import url("~@/components/form/formItem.less");

.m-form-row {
  padding: 0;

  .m-input {
    padding: 0;

    input.right {
      text-align: right;
    }
  }
}
.date_ctrl{
    background: #fff;
}
.date_roll_mask{
    padding: 0;
}
.lcalendar_cancel{
    color: #666;
}
</style>
