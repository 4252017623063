<template>
  <section class="c-group-item">
    <Avatar :user="user" :readonly="true" />
    <h2 class="m-text-cut">
      {{ userName }}
      <UserTitle :user="user" />
    </h2>
    <svg v-if="choose" class="m-style-svg m-svg-def"><use xlink:href="#icon-qs_focused" /></svg>
  </section>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'UserItem',
    props: {
      user: { type: Object, required: true },
      showAction: { type: Boolean, default: true },
      showRole: { type: Boolean, default: true },
      choose: { type: Boolean, default: false },
    },
    data () {
      return {
        loading: false,
      }
    },
    computed: {
      ...mapState(['CURRENTUSER']),
      avatar () {
        const avatar = this.user.avatar || {}
        return avatar.url || null
      },
      userName () {
        const name = this.$getUserName(this.user)
        return name
      },
    },
  }
</script>

<style lang="less" scoped>
.c-group-item {
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: #fff;

  .m-avatar-box {
    margin-right: 30px;
  }

  h2 {
    font-size: 32px;
    max-width: 11em;
  }

  /deep/ svg.m-svg-def {
    color: #6085CC;
    width: 20px;
    margin-left: auto;
  }
}
</style>
