<template>
  <div class="c-article-like">
    <img
      v-if="liked"
      :src="require('@/images/detail_praise@2x.png')"
      class="likebtn"
      @click="checkLike"
    >
    <img
      v-else
      :src="require('@/images/detail_praised@2x.png')"
      class="likebtn"
      @click="checkLike"
    >
    <RouterLink
      class="like-list"
      tag="div"
      to="likers"
      append
    >
      <template v-if="list.length && likeCount">
        <div class="avatar-list">
          <PostLikeAvatar
            v-for="({ user = {}, id }, index) in list"
            :key="id"
            :user="user"
            :style="{ zIndex: 5 - index }"
            class="liker-avatar"
            size="tiny"
          />
          <svg v-if="likeCount > 5" class="m-style-svg m-svg-def entry__item--append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </template>
    </RouterLink>
    <div class="like-count" :class="likeCount == 0 ? 'mt20' : ''">
      {{ likeCount | formatNum | t("article.like_count") }}
    </div>
  </div>
</template>

<script>
  import PostLikeAvatar from './PostLikeAvatar'

  export default {
    name: 'PostLike',
    components: {
      PostLikeAvatar,
    },
    filters: {
      getAvatar (user = {}) {
        const avatar = user.avatar
        if (!avatar) return ''
        return avatar.url || ''
      },
    },
    props: {
      likers: { type: Array, default: () => [] },
      likeCount: { type: Number, default: 0 },
      liked: { type: Boolean, default: false },
    },
    computed: {
      list () {
        return this.likers.slice(0, 5)
      },
    },
    methods: {
      checkLike () {
        this.$emit('checkLike')
      },
    },
  }
</script>

<style lang="less" scoped>
.c-article-like {
  padding: 45px 30px;
  font-size: 24px;
  color: #ccc;
  clear: both;
  .likebtn {
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
  }
  .like-list {
    flex: auto;
    display: flex;
    align-items: center;
    color: @primary;

    .avatar-list {
      margin: auto;

      .liker-avatar {
        width: 50px;
        height: 50px;
        margin: 30px 15px;
      }
    }
  }

  .like-count {
    width: 100%;
    margin: auto;
    color: #b2b2b2;
    text-align: center;
  }

  .article-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 30px;
  }
}
.mt20 {
  margin-top: 20px !important;
}
</style>
