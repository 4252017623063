<template>
  <div class="vote">
    <header>
      <div class="vote-title">{{ vote.title }}（{{ vote.type == 2 ? '多选' : '单选' }}）</div>
      <div class="vote-subtitle">
        <span>投票时间：{{ showTime(vote.s_time) }}~{{ showTime(vote.e_time) }}</span><span v-if="isEnd" class="marginl10">已结束</span><span class="right">{{ vote.count || 0 }}人参与</span>
      </div>
    </header>
    <section>
      <div
        v-for="(option, index) in vote.options"
        :key="option.id"
        class="vote-list"
        @click="voteOption(index)"
      >
        <template v-if="vote.type == 2">
          <img
            v-if="option.is_vote"
            :src="require('@/images/multi_select_on@2x.png')"
            class="vote-list-choose"
          >
          <img
            v-else
            :src="require('@/images/multi_select@2x.png')"
            class="vote-list-choose"
          >
        </template>
        <template v-else>
          <img
            v-if="option.is_vote"
            :src="require('@/images/radio_on@2x.png')"
            class="vote-list-choose"
          >
          <img
            v-else
            :src="require('@/images/radio@2x.png')"
            class="vote-list-choose"
          >
        </template>

        <img
          v-if="option.images"
          :src="`${$http.defaults.baseURL}/files/${option.images.id}`"
          class="vote-list-logo"
          @click.stop="onFileClick($event, option.images)"
        >
        <div class="vote-list-content">
          {{ option.content }}
        </div>
        <div v-if="is_vote && option.count > 0" class="vote-list-count">{{ option.count || 0 }}票</div>
      </div>
    </section>
    <footer class="footer">
      <button v-if="is_vote" class="un">已投票</button>
      <button v-else-if="!isStart" class="un">未开始</button>
      <button v-else-if="isEnd" class="un">已结束</button>
      <button v-else @click="doVote">确认投票</button>
    </footer>
  </div>
</template>

<script>
  import { transTime } from '@/util'

  export default {
    name: 'ShowVote',
    props: {
      vote: { type: Object, default: () => {} },
      feedId: { type: Number, default: 0 },
      is_vote: { type: Boolean, default: false },
    },
    data () {
      return {
        options: [], // 投票
      }
    },
    computed: {
      isStart () {
        if (!this.vote.s_time) {
          return false
        }
        return new Date(this.vote.s_time.replace(/-/g, '/')) < new Date()
      },
      isEnd () {
        if (!this.vote.e_time) {
          return false
        }
        return new Date(this.vote.e_time.replace(/-/g, '/')) <= new Date()
      },
    },
    watch: {
      vote (vote) {
        this.options = vote.options
      },
    },
    methods: {
      showTime (time) {
        if (!time) {
          return ''
        }
        time = time.replace(/-/g, '/')

        let str = ''
        const date = transTime(time)
        const now = new Date()
        const nowYear = now.getFullYear()
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        month = month < 10 ? ('0' + month) : month
        let day = date.getDate()
        day = day < 10 ? ('0' + day) : day
        if (nowYear != year) {
          str += year + '.'
        }

        str += month + '.' + day

        return str
      },
      voteOption (index) {
        if (this.is_vote) return

        if (this.vote.type == 1) {
          const i = this.options.findIndex(item => item.is_vote)
          if (i > -1) {
            this.options[i].is_vote = false
          }
        }

        this.options[index].is_vote = !this.options[index].is_vote
      },
      doVote () {
        const options = this.options.filter(item => item.is_vote)
        this.options = this.options.map(item => {
          if (item.is_vote) ++item.count
          return item
        })
        this.$emit('checkVote', options)
      },
      onFileClick ($event, img) {
        const component = this.$parent
        const src = `${this.$http.defaults.baseURL}/files/${img.id}`
        const index = 0
        const el = $event.target
        const [w, h] = img.size.split('x')
        const images = [{
          ...img,
          w,
          h,
          el,
          src,
          index,
        }]
        // const images = this.images.map((img, index) => {
        //   const el = $event.target
        //   const src = `${this.$http.defaults.baseURL}/files/${img.id}`
        //   const [w, h] = img.size.split('x')
        //   img.w = parseInt(w)
        //   img.h = parseInt(h)
        //   return {
        //     ...img,
        //     el,
        //     src,
        //     index,
        //   }
        // })

        console.log(images)

        this.$bus.$emit('mvGallery', { component, index, images })
      },
    },
  }
</script>

<style lang="less" scoped>
.vote {
  padding: 40px 30px;
  background-color: #f4f4f4;
  &-title {
    color: #1a1a1a;
    font-size: 30px;
  }
  &-subtitle {
    display: flex;
    align-items: center;
    margin: 20px 0 10px;
    color: #999999;
    font-size: 20px;
    .right {
      margin-left: auto;
    }
  }
  &-list {
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px 20px;
    margin-top: 20px;
    background-color: #fff;
    &-choose {
      width: 30px;
      height: 30px;
      margin-right: 28px;
    }
    &-logo {
      width: 90px;
      height: 90px;
      margin-right: 18px;
      object-fit: cover;
    }
    &-content {
      flex: 1;
      color: #333333;
      font-size: 22px;
    }
    &-count {
      vertical-align: middle;
      padding: 10px 18px;
      color: #fff;
      font-size: 26px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      margin: -10px 0;
    }
  }
}
.marginl10 {
  margin-left: 10px;
}
.footer {
  margin-top: 30px;
  button {
    width: 100%;
    height: 75px;
    line-height: 75px;
    color: #fff;
    font-size: 28px;
    background-color: @primary;
    border-radius: 6px;
  }

  button.un {
    background: #B2B2B2;
  }
}
</style>
