<template>
  <main>
    <div class="v-tit">
      {{ title }}
      <span v-if="hasDelete" @click="deleteOption">删除选项</span>
    </div>
    <div class="v-content">
      <textarea
        v-model="content"
        :placeholder="placeholder"
        rows="3"
      />
      <VoteOptionImg :limit="1" :index="index" />
    </div>
  </main>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VoteOptionImg from './VoteOptionImg'

  export default {
    name: 'VoteOption',
    components: { VoteOptionImg },
    props: {
      title: { type: String, required: true },
      placeholder: { type: String, default: '选项描述，50字以内（必填）', required: false },
      option: { type: Object, required: false },
      hasDelete: { type: Boolean, default: false },
      index: { type: Number, default: 0 },
    },
    data () {
      return {
        content: '',
      }
    },
    computed: {
      ...mapGetters(['composePhoto']),
    },
    methods: {
      deleteOption () {
        this.$emit('deleteOption', this.index)
      },
    },
  }
</script>

<style lang="less" scoped>

</style>
