<template>
  <div class="p-group-members">
    <SearchBar v-model="keyword" class="search-bar" />

    <JoLoadMore
      ref="loadmore"
      :show-bottom="hasData && !keyword"
      @onRefresh="onRefresh"
      @onLoadMore="onLoadMore"
    >
      <template v-if="keyword">
        <ul class="member-list">
          <li v-for="m in searchList" :key="m.id">
            <GroupUserItem
              :member="m"
              :joined="group.joined"
              @more="onMoreClick(m)"
            />
          </li>
        </ul>
      </template>
      <template v-else-if="hasData">
        <h3>{{ $t('group.members.admin') }}({{ administrators.length }})</h3>
        <ul class="member-list">
          <li v-for="m in administrators" :key="m.id">
            <GroupUserItem
              :joined="group.joined"
              :member="m"
              @more="onMoreClick(m)"
            />
          </li>
        </ul>

        <h3>{{ $t('group.members.name') }}({{ group.users_count || 0 }})</h3>
        <ul class="member-list">
          <li v-for="m in members" :key="m.id">
            <GroupUserItem
              :joined="group.joined"
              :member="m"
              @more="onMoreClick(m)"
            />
          </li>
        </ul>
      </template>
    </JoLoadMore>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { limit } from '@/api'
  import * as api from '@/api/group'
  import SearchBar from '@/components/common/SearchBar'
  import GroupUserItem from '../components/GroupUserItem'

  export default {
    name: 'GroupMembers',
    components: { SearchBar, GroupUserItem },
    data () {
      return {
        keyword: '',
        searchList: [],
        groupDetail: {},
      }
    },
    computed: {
      ...mapState('group', {
        members: 'members',
        administrators: 'administrators',
        group: 'current',
      }),
      ...mapState({
        currentUser: 'CURRENTUSER',
      }),
      groupId () {
        return Number(this.$route.params.groupId)
      },
      hasData () {
        return Boolean(this.administrators.length + this.members.length)
      },
    },
    watch: {
      keyword (val, oldVal) {
        if (val.trim() === '') return (this.searchList = [])
        if (val.trim() === oldVal.trim()) return
        this.searchUser(val)
      },
    },
    created () {
      if (!this.group.id) { this.groupDetail = this.fetchGroup() }
    },
    mounted () {
      if (this.group.id) this.$refs.loadmore.beforeRefresh()
    },
    methods: {
      ...mapActions('group', [
        'getMembers',
        'getAdministrators',
        'getGroupById',
      ]),
      async onRefresh () {
        this.getAdministrators({ groupId: this.groupId })
        const noMore = await this.getMembers({ groupId: this.groupId })
        this.$refs.loadmore.afterRefresh(noMore)
      },
      async onLoadMore () {
        const lastMember = [...this.members].pop() || {}
        const noMore = await this.getMembers({
          groupId: this.groupId,
          after: lastMember.id,
        })
        this.$refs.loadmore.afterLoadMore(noMore)
      },
      async fetchGroup () {
        await this.getGroupById({ groupId: this.groupId })
        this.$refs.loadmore.beforeRefresh()
      },
      async searchUser (keyword) {
        this.$refs.loadmore.beforeRefresh()
        const { data: list } = await api.getMembers(this.groupId, { name: keyword })
        this.searchList = list
        this.$refs.loadmore.afterRefresh(list.length < limit)
      },
      onMoreClick (member) {
        const actions = []
        if (member.role === 'member') {
          actions.push({
            text: this.$t('group.black_join'),
            method: () => {
              const actions = [
                {
                  text: this.$t('group.black_join'),
                  method: async () => {
                    await this.$store.dispatch('group/addToBlackList', {
                      groupId: this.groupId,
                      memberId: member.id,
                    })
                    this.member = this.member.filter(m => m.id !== member.id)
                    this.$Message.success(this.$t('success'))
                    this.$refs.loadmore.beforeRefresh()
                  },
                },
              ]
              setTimeout(() => {
                this.$bus.$emit(
                  'actionSheet',
                  actions,
                  this.$t('cancel'),
                  this.$t('group.black_confirm'),
                )
              }, 200)
            },
          })
        }
        if (actions.length) this.$bus.$emit('actionSheet', actions)
      },
    },
  }
</script>

<style lang="less" scoped>
  .p-group-members {
    h3 {
      padding: 15px 30px;
      font-size: 28px;
    }

    .member-list {
      background-color: #fff;
    }
  }
</style>
