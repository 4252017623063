<template>
  <Transition name="popr">
    <div v-if="visible" class="p-choose-group">
      <CommonHeader :back="close" head_title="@部门">
        <span
          slot="right"
          class="check-vote"
          @click="close"
        >
          确定
        </span>
      </CommonHeader>
      <main id="searchDm">
        <DepartmentList class="list-container" :list="list" :choose="choseDepartments" :collapse="collapse" @onChoose="onChoose" @onCollapse="onCollapse"></DepartmentList>
      </main>
    </div>
  </Transition>
</template>

<script>
  import JoLoadMoreFixed from '@/components/JoLoadMoreFixed.vue'
  import * as userApi from '@/api/user'
  import DepartmentList from "@/page/group/components/DepartmentList";

  export default {
    name: 'ChooseDepartments',
    components: {DepartmentList, JoLoadMoreFixed },
    props: {
      choose: { type: Array, required: false },
    },
    data () {
      return {
        visible: false,
        list: [],
        choseDepartments: [],
        collapse: {}, // 能获取到对应数据则为展开，否则为收起
      }
    },
    watch: {
      choose (choose) {
        this.choseDepartments = choose
      },
    },
    mounted() {
      this.fetch()
    },
    methods: {
      show () {
        this.visible = true
      },
      close () {
        this.$emit('change', this.choseDepartments)

        this.visible = false
      },
      async fetch() {
        const { data } = await userApi.getDepartment()
        this.list = data
      },
      // 选中
      onChoose(data) {
        if (this.choseDepartments.some(item => item.id === data.id)) {
          this.choseDepartments = this.choseDepartments.filter(item => item.id !== data.id)
        } else {
          this.choseDepartments.push(data)
        }
      },
      // 展开收起
      onCollapse(data) {
        this.collapse = { ...this.collapse, [data.id]: !this.collapse[data.id]  }
      },
    },
  }
</script>

<style lang="less">
.p-choose-group {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;

  .check-vote {
    color: @primary;
  }

  /deep/ .c-common-header {
    border: 0;
  }

  main {
    height: calc(100% - 0.9rem);
    overflow: auto;
    background: @gray-bg;
  }

  .list-container {
    margin-top: 20px;
  }

  .list {
    background-color: #fff;
    font-size: 32px;
    color: #1A1A1A;

    li {
      border-top: 1px solid @border-color;

      .title {
        padding: 0 27px;
        height: 100px;
        display: flex;
        align-items: center;
      }

      .t-icon {
        width: 32px;
        height: 32px;
        background: #E5E5E6;
        border-radius: 50%;
        margin: auto 0;

        > img {
          width: 32px;
          height: 32px;
          display: block;
          margin: auto;
          color: #fff;
          border-radius: 50%;
        }
      }
      .t-data {
        margin-left: 41px;
      }
    }
  }
}

.children {
  margin-left: 73px;
}

.arrow {
  margin-left: auto;
  transition: all .3s ease;

  &.active {
    transform: rotate(90deg);
  }
}

.collapse {
  height: 0;
  overflow: hidden;
  transition: height .3s ease;

  &.active {
    height: 100%;
  }
}
</style>
