<template>
  <div class="p-group-post-create">
    <CommonHeader>
      <span slot="left" @click.stop="goBack">
        {{ $t("cancel") }}
      </span>
      <span
        slot="right"
        :class="{ disabled }"
        class="primary"
        @click="onSubmit"
      >
        发布
      </span>
    </CommonHeader>

    <main>
      <!-- 标题 -->
      <div class="title-wrap">
        <input
          v-model="title"
          type="text"
          placeholder="输入标题，50字以内(必填)"
          maxlength="50"
        />
      </div>
      <!-- 内容 -->
      <div class="content-wrap" :style="{ height: height }">
        <div class="textarea-shadow" />
        <!-- 用于撑起文本框自适应高度 -->
        <textarea v-model="content" placeholder="输入动态内容(选填)" rows="1" />
        <!-- 图片或视频 -->
        <ImageList
          v-if="postType !== ''"
          :type="postType"
          :accept="postType === 'image' ? 'image/*' : 'video/*'"
          :limit="postType === 'image' ? 9 : 1"
          :initial_pics="initial_pics"
          :initial_video="initial_video"
        />
      </div>
    </main>
    <!-- 阅读奖励文章设置 -->
    <div v-if="isAuthRead" class="isAuthRead">
      <section class="form-select-item">
        <label>设置阅读奖励类型</label>
        <div class="input-wrap minw100" @click="setActivetype">
          <span class="placeholder">{{ activename }}</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </section>
      <div v-if="activeType > 0">
        <section class="form-select-item read-article-box">
          <label>{{ numname }}</label>
          <div class="read-count">
            <md-input-item v-model="lottery_limit" title="" type="bankCard" />
          </div>
        </section>
        <section class="form-select-item read-time-box">
          <label>截止时间</label>
          <div class="read-time" @click="setReadTime">
            {{ lottery_date ? lottery_date : "" }}
          </div>
          <md-date-picker
            v-if="isDatePickerShow"
            ref="datePicker"
            v-model="isDatePickerShow"
            type="datetime"
            title="截止时间"
            large-radius
            :text-render="textRender"
            :default-date="currentDate"
            :min-date="minDate"
            class="read-date"
            @confirm="confirmTime"
          />
        </section>
      </div>
    </div>

    <!-- 付费内容-->
    <!-- <section class="form-select-item">
      <label>匿名发帖</label>
      <div class="input-wrap">
        <md-switch v-model="cur" @change="openSwitch(cur)" />

        <div class="switch">
          <div class="switch-bg" :class="{ on: cur == 1 }">
            <div
              class="switch-btn"
              :class="{ on: cur == 1 }"
              @click="openSwitch"
            />
          </div>
        </div>
        <div class="input-box">
          <div class="input-left" :class="{'active-show':tabActiveShow == 2}" @click="tabActiveShow = 2">否</div>
          <div class="input-right" :class="{'active-show':tabActiveShow == 1}" @click="tabActiveShow = 1">是</div>
        </div>
      </div>
    </section> -->
    <ChooseTags
      v-model="content_tags"
      type="content"
      :label="$t('group.post.content_tag')"
      :placeholder="$t('group.post.placeholder.content_tag')"
    />
    <!-- 选择板块 -->
    <section class="form-select-item" @click="selectGroup">
      <label>选择版块 <span>(必填)</span></label>
      <div class="input-wrap">
        <span class="placeholder">{{ group.name }}</span>
        <svg class="m-style-svg m-svg-def m-entry-append">
          <use xlink:href="#icon-arrow-right" />
        </svg>
      </div>
    </section>

    <!-- 付费内容-->
    <section class="form-select-item">
      <label>付费查看 <span>(选填)</span></label>
      <div class="input-wrap">
        <input
          v-model="amount"
          style="text-align: right"
          type="number"
          placeholder="若免费，则不输入积分数"
        />
      </div>
    </section>

    <!--高级选项-->
    <section class="form-select-item mt20" @click="onCollapse">
      <label>高级选项</label>
      <div class="input-wrap">
        <svg
          class="m-style-svg m-svg-def m-entry-append arrow-bottom"
          :class="collapse || 'active'"
        >
          <use xlink:href="#icon-arrow-right" />
        </svg>
      </div>
    </section>
    <div class="collapse" :class="collapse || 'active'">
      <!-- 内容标签选择 -->

      <!-- @某人 -->
      <section class="form-select-item" :choose="at" @click="selectUser">
        <label>@他 <span>(选填)</span></label>
        <div class="input-wrap">
          <span class="placeholder">
            <span v-for="(user, index) in at" v-if="index < 2" :key="user.id"
              >{{ userName(user)
              }}<font>{{
                at.length > 2 && index === 1
                  ? "..."
                  : at.length - 1 != index
                  ? "、"
                  : ""
              }}</font></span
            >
          </span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </section>

      <!-- @部门 -->
      <!-- <section
        class="form-select-item"
        :choose="at_department"
        @click="selectDepartment"
      >
        <label>@部门 <span>(选填)</span></label>
        <div class="input-wrap">
        <span class="placeholder">
          <span
            v-for="(item, index) in at_department"
            v-if="index < 2"
            :key="item.id"
          >{{ item.name }}<font>{{ (at_department.length > 2 && index === 1) ? '...' : (at_department.length-1 != index ? '、' : '') }}</font></span>
        </span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </section> -->

      <!-- 投票 -->
      <section v-if="publish_vote" class="form-select-item" @click="createVote">
        <label>添加投票 <span>(选填)</span></label>
        <div class="input-wrap">
          <span v-if="hasVote" class="placeholder">已添加</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </section>

      <!-- 发布时间 -->
      <section
        v-if="publishTiming"
        class="form-select-item"
        @click="choosePublish"
      >
        <label>选择发布时间</label>
        <div class="input-wrap">
          <span v-if="publish == 1" class="placeholder">立即发布</span>
          <span v-if="publish == 2" class="placeholder">{{
            publish_time
          }}</span>
          <svg class="m-style-svg m-svg-def m-entry-append">
            <use xlink:href="#icon-arrow-right" />
          </svg>
        </div>
      </section>
    </div>

    <ChooseActive
      :active-type="activeType"
      :visible="visibleActive"
      @close="closeActive"
    />
    <ChooseGroup ref="chooseGroup" :choose="group" @change="onGroupChange" />
    <ChooseUsers ref="chooseUsers" :choose="at" @change="onUsersChange" />
    <ChooseDepartments
      ref="chooseDepartments"
      :choose="at_department"
      @change="onDepartmentsChange"
    />
    <CreateVote
      v-if="publish_vote"
      ref="createVote"
      :vote="vote"
      @onVoteChange="onVoteChange"
    />
    <ChoosePublish
      ref="choosePublish"
      :publish="publish"
      :publish_time="publish_time"
      @onChoosePublish="onChoosePublish"
    />

    <PostSuccess
      ref="postSuccess"
      :group_id="group.id || 0"
      :post_id="id"
      :publish="publish"
      :publish_time="publish_time"
    />
  </div>
</template>

<script>
import lstore from "@/plugins/lstore/";
import moment from "moment";
import { DatePicker, Field, FieldItem, Dialog, Switch } from "mand-mobile";

import { mapGetters } from "vuex";
import { transTime } from "@/util";
import ChooseActive from "./components/ChooseActive.vue";
import ChooseGroup from "./components/ChooseGroup.vue";
import ChooseTags from "./components/ChooseTags.vue";
import ChooseUsers from "./components/ChooseUsers.vue";
import ChooseDepartments from "./components/ChooseDepartments.vue";
import CreateVote from "./components/CreateVote.vue";
import ChoosePublish from "./components/ChoosePublish.vue";
import PostSuccess from "./components/PostSuccess.vue";
import ImageList from "@/page/post/components/ImageList";

export default {
  name: "GroupPostCreate",
  components: {
    [DatePicker.name]: DatePicker,
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    ChooseActive,
    ChooseGroup,
    ChooseDepartments,
    ImageList,
    ChooseTags,
    ChooseUsers,
    CreateVote,
    ChoosePublish,
    PostSuccess,
  },
  data() {
    return {
      activeType: 0,
      visibleActive: false,
      lottery_limit: 0,
      lottery_date: "",
      isDatePickerShow: false,
      loading: false,
      tabActiveShow: 2,
      id: 0,
      title: "",
      content: "",
      group: {},
      initial_pics: [],
      initial_video: {},
      draft: {},
      content_tags: [],
      at: [],
      vote: {},
      publish: 1,
      publish_time: null,
      height: "calc(100% - 270px)",
      isneedLeave: true,
      cur: false,
      amount: "",
      at_department: [],
      collapse: true,
    };
  },
  created() {
    this.initGroup();
    this.initDraft();
    this.loading = true;
    this.lottery_date = moment(new Date()).format("YYYY/MM/DD HH:mm");
  },
  computed: {
    minDate() {
      if (this.lottery_date) return moment(this.lottery_date).toDate();
      return new Date();
    },
    numname() {
      return this.activeConfig.find((v) => v.type == this.activeType).numname;
    },
    activename() {
      return this.activeConfig.find((v) => v.type == this.activeType).title;
    },
    activeConfig() {
      return lstore.getData("H5_CUR_USER").activity_conf;
    },
    isAuthRead() {
      //  判断是否有阅读抽奖权限
      let result = false;
      const user = lstore.getData("H5_CUR_USER");
      if (user) {
        if (parseInt(user.is_activity) === 1) {
          result = true;
        }
      }
      return result;
    },
    currentDate() {
      if (this.lottery_date) {
        return moment(this.lottery_date).toDate();
      }
      return new Date();
    },
    ...mapGetters(["composePhoto", "composeVideo"]),
    user() {
      return this.$store.state.CURRENTUSER;
    },
    // 发布类型。image: 图文，video:视频，默认图文
    postType() {
      return this.$route.query.type || "image";
    },
    groupId() {
      return this.$route.query.group || 0;
    },
    draftId() {
      return this.$route.query.draft || 0;
    },
    disabled() {
      if (!this.groupId && !this.group.id) return true;

      return !this.title;
    },
    hasVote() {
      return this.vote.hasOwnProperty("title");
    },
    publishTiming() {
      return this.user.permission.publish_timing || false;
    },
    publish_vote() {
      return this.user.permission.publish_vote || false;
    },
  },
  watch: {
    composePhoto() {
      // let h = 'calc(100% - 2.7rem)'
      // if (this.composePhoto.length > 3) {
      //   h = 'calc(100% - 4.2rem)'
      // }
      const h = "calc(100% - 10rem)";
      this.height = h;
    },
  },
  mounted() {
    if (this.draftId > 0) {
      // this.$matomoTrackPageView(`编辑草稿 - 旭辉员工论坛（SPA）`)
    } else {
      // this.$matomoTrackPageView(`发布动态 - 旭辉员工论坛（SPA）`)
    }
  },

  // beforeDestroy () {
  //   this.onBack()
  // },
  beforeRouteLeave(to, from, next) {
    if (this.isneedLeave && this.id === 0) {
      next(false);
      this.isneedLeave = false;
      this.onBack();
    } else {
      next();
    }
  },
  methods: {
    closeActive(value) {
      this.activeType = value;
      this.visibleActive = false;
    },
    setActivetype() {
      this.visibleActive = true;
    },
    confirmTime() {
      const time = this.$refs.datePicker.getFormatDate("yyyy/MM/dd hh:mm");
      this.lottery_date = time;
    },
    setReadTime() {
      this.isDatePickerShow = true;
    },
    textRender() {
      const args = Array.prototype.slice.call(arguments);
      const typeFormat = args[0];
      const column2Value = args[3];
      if (typeFormat === "dd") {
        return `${column2Value}日`;
      }
    },
    userName(user) {
      const name = this.$getUserName(user);
      return name;
    },
    openSwitch(event) {
      this.cur = event;
      this.tabActiveShow = this.cur ? 1 : 2;
    },
    onBack() {
      // 动态图片/视频。无输入内容正常退出（板块是默认选中，不计算）
      if (
        this.title.length > 0 ||
        this.content.length > 0 ||
        this.content_tags.length > 0 ||
        this.amount > 0 ||
        this.at.length > 0 ||
        this.at_department.length > 0 ||
        this.vote.hasOwnProperty("title") ||
        this.composePhoto.length > 0 ||
        this.composeVideo.length > 0
      ) {
        this.$bus.$emit("popupConfirm", {
          content: "是否保存至草稿箱？",
          onClose: () => {
            this.goBack();
          },
          onConfirm: () => {
            this.saveDraft();
          },
          confirmText: "保存",
          cancelText: "不保存",
        });
      } else {
        this.goBack();
      }
    },
    getDate(time) {
      time = transTime(time);
      const year = time.getFullYear();
      const month = time.getMonth() + 1;
      const day = time.getDate();

      return year + "-" + month + "-" + day;
    },
    // 草稿
    async saveDraft() {
      // 内容标签
      const tags = this.content_tags.map((item) => {
        return item.id;
      });
      // 动态图片
      const images = this.composePhoto.map((item) => {
        return item.id;
      });
      // 动态视频
      const video = this.composeVideo;

      // 邀请用户AT
      const at = this.at.map((item) => {
        return item.id;
      });
      // @部门
      const at_department = this.at_department.map((item) => {
        return item.id;
      });

      const params = {
        title: this.title,
        group_id: this.group.id,
        body: this.content,
        summary: this.content.substr(0, 144),
        tag: tags,
        is_anonymous: this.tabActiveShow,
        at: at,
        amount: this.amount,
        at_department,
        type: this.hasVote ? 2 : 1,
        vote: this.vote,
        publish: this.publish,
        anonymous: 2, // 不匿名
        post_from: 2,
        file_type: this.postType === "image" ? 1 : 2, // 1: 图片，2: 视频
        draft_id: this.draftId || 0,
      };

      // 阅读奖励文章
      if (this.isAuthRead) {
        params.is_activity = this.activeType;
        if (this.activeType > 0) {
          params.activity_lottery_limit = this.lottery_limit;
          params.activity_lottery_date = moment(this.lottery_date).format(
            "YYYYMMDDHHmm"
          );
        }
      }

      // 图片/视频
      if (this.postType === "image") {
        params.images = images;
      } else {
        params.video = { resource: video.resource, cover: video.cover };
      }

      if (params.publish == 2) {
        params.publish_time = this.publish_time;
      }
      let data = { message: "失败" };
      if (this.draft.id > 0) {
        data = await this.$store.dispatch("group/UpdateDraftPost", params);
      } else {
        data = await this.$store.dispatch("group/createDraftPost", params);
      }
      if (data) {
        this.$Message.success(data.message);
        this.goBack();
      }
    },
    // 提交
    async onSubmit() {
      // 阅读奖励文章
      if (this.isAuthRead) {
        if (this.activeType > 0) {
          if (Number(this.lottery_limit) === 0) {
            Dialog.alert({
              title: "",
              content: `${this.numname}不能为空`,
              cancelText: "取消",
              confirmText: "确定",
              onConfirm: () => {},
            });
            return false;
          }

          if (this.lottery_date === "") {
            Dialog.alert({
              title: "",
              content: "日期不能为空",
              cancelText: "取消",
              confirmText: "确定",
              onConfirm: () => {},
            });
            return false;
          }
        }
      }

      if (this.disabled || !this.loading) return;
      this.loading = false;
      // 内容标签
      const tags = this.content_tags.map((item) => {
        return item.id;
      });
      // 动态图片
      const images = this.composePhoto.map((item) => {
        return item.id;
      });
      // 动态视频
      const video = this.composeVideo;
      // 邀请用户AT
      const at = this.at.map((item) => {
        return item.id;
      });
      // @部门
      const at_department = this.at_department.map((item) => {
        return item.id;
      });
      const params = {
        title: this.title,
        groupId: this.group.id,
        body: this.content,
        summary: this.content.substr(0, 144),
        tag: tags,
        at: at,
        is_anonymous: this.tabActiveShow,
        amount: this.amount,
        at_department,
        type: this.hasVote ? 2 : 1,
        vote: this.vote,
        publish: this.publish,
        anonymous: 2, // 不匿名
        post_from: 2,
        file_type: this.postType === "image" ? 1 : 2, // 1: 图片，2: 视频
      };

      // 阅读奖励文章
      if (this.isAuthRead) {
        params.is_activity = this.activeType;
        if (this.activeType > 0) {
          params.activity_lottery_limit = this.lottery_limit;
          params.activity_lottery_date = moment(this.lottery_date).format(
            "YYYYMMDDHHmm"
          );
        }
      }

      // 图片视频
      if (this.postType === "image") {
        params.images = images;
      } else {
        params.video = { resource: video.resource, cover: video.cover };
      }

      if (this.draftId) {
        params.draft_id = this.draftId;
      }
      if (params.publish == 2) {
        params.publish_time = this.publish_time;
      }

      await this.$store
        .dispatch("group/createPost", params)
        .then((data) => {
          // this.$Message.success(data.message)
          this.id = data.post.id;
          // 立即发布直接进入详情
          if (this.publish === 1) {
            this.$router.replace(`/groups/${this.group.id}/posts/${this.id}`);
          } else {
            this.$refs.postSuccess.show();
          }
          // this.$router.replace({
          //   name: 'groupPostDetail',
          //   params: { groupId: data.post.group_id, postId: data.post.id },
          // })
        })
        .finally(() => {
          this.loading = true;
        });
    },
    // 阅读奖励文章
    setForm() {
      if (this.isAuthRead) {
        this.activeType = this.draft.is_activity;
        this.lottery_limit = this.draft.activity_lottery_limit;
        const str = this.draft.activity_lottery_date;
        const year = str.substr(0, 4);
        const month = str.substr(4, 2);
        const day = str.substr(6, 2);
        const h = str.substr(8, 2);
        const m = str.substr(10, 2);
        this.lottery_date = `${year}/${month}/${day} ${h}:${m}`;
      }
    },
    async initGroup() {
      // 从 store 中读取当前圈子信息
      if (!this.group.id && this.groupId) {
        this.group = this.$store.state.group.current;
      }

      // 如果没有读到, 去远端拉取
      if (!this.group.id && this.groupId) {
        this.group = await this.$store.dispatch("group/getGroupById", {
          groupId: this.groupId,
        });
      }
    },
    async initDraft() {
      if (!this.draft.id && this.draftId) {
        this.draft = await this.$store.dispatch("group/getDraftById", {
          draftId: this.draftId,
        });
        // 阅读奖励回显
        this.setForm();
        if (this.draft.group) {
          this.group = this.draft.group;
        }
        this.title = this.draft.title || "";
        this.content = this.draft.summary || "";
        this.amount = this.draft.amount || "";
        // 图片信息
        const images = this.draft.images;
        this.initial_pics = images.map((img) => {
          return {
            id: img.id,
            src: `${this.$http.defaults.baseURL}/files/${img.id}`,
            file: null,
            loading: false,
            error: false,
          };
        });
        // 视频
        if (this.draft.video) {
          this.initial_video = {
            resource: this.draft.video.resource.node,
            cover: this.draft.video.cover.node,
            src: this.draft.video.resource.url, // blob地址
            type: "", // mimetype
            file: null, // file
            error: false,
            loading: false,
          };
        }
        // 内容标签
        this.content_tags = this.draft.tags || [];
        this.at = this.draft.at || [];
        this.at_department = this.draft.at_department || [];
        this.type = this.draft.type;
        // 投票
        if (this.draft.vote) {
          let { options, ...vote } = this.draft.vote;

          if (options.length > 0) {
            options = options.map((item) => {
              const img = item.images;
              if (img) {
                item.images = img.id;
                item.pics = [
                  {
                    id: img.id,
                    src: `${this.$http.defaults.baseURL}/files/${img.id}`,
                    file: null,
                    loading: false,
                    error: false,
                  },
                ];
              }
              return item;
            });
          }

          vote.option = options;
          vote.s_time = this.getDate(vote.s_time);
          vote.e_time = this.getDate(vote.e_time);
          this.vote = vote;
        }
        if (this.draft.publish == 2) {
          this.publish = 2;
          this.publish_time = this.draft.dead_line;
        }
      }
    },

    selectGroup() {
      this.$refs.chooseGroup.show();
    },
    onGroupChange(group) {
      this.group = group;
    },
    selectUser() {
      this.$refs.chooseUsers.show();
    },
    selectDepartment() {
      this.$refs.chooseDepartments.show();
    },
    onUsersChange(users) {
      this.at = users;
    },
    onDepartmentsChange(departments) {
      this.at_department = departments;
    },
    createVote() {
      this.$refs.createVote.show();
    },
    onVoteChange(vote) {
      this.vote = vote;
    },
    choosePublish() {
      this.$refs.choosePublish.show();
    },
    onChoosePublish(publish, publish_time) {
      this.publish = publish;
      this.publish_time = publish_time;
    },

    onCollapse() {
      this.collapse = !this.collapse;
    },
  },
};
</script>

<style lang="less" scoped>
.p-group-post-create {
  flex-direction: column;
  height: 100%;

  /deep/ .c-common-header {
    border: 0;

    .left,
    .right {
      padding: 0 30px;
    }
  }

  .disabled {
    color: #ccc;
  }

  .isAuthRead {
    margin-bottom: 20px;
    .minw100 {
      min-width: 200px;
      text-align: right;
    }
  }

  .form-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid @border-color; /* no */
    background: #fff;
    font-size: 28px;

    label > span {
      color: #b2b2b2;
    }

    .read-count {
      border: 1px solid #ccc;
      width: 300px;
      /deep/ .md-field-item {
        height: 60px;
        .md-field-item-content {
          height: 60px;
          display: inline-block;
          &::before {
            border-bottom: 0;
          }

          .md-input-item-input {
            height: 60px;
            text-align: center;
            padding: 0 20px;
          }
        }
      }
    }

    &.read-article-box {
      display: flex;
      align-items: center;
    }

    &.read-time-box {
      display: flex;
      align-items: center;
    }

    .read-time {
      border: 1px solid #ccc;
      width: 300px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .read-date {
      position: absolute;
      z-index: 1;
    }

    .input-wrap {
      svg {
        vertical-align: middle;
      }
      .input-box {
        display: flex;
        width: 120px;
        margin-top: 10px;
        .input-left {
          border: 1px solid #999;
          width: 50%;
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          font-size: 12px;
          border-right: none;
          text-align: center;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        .input-right {
          border: 1px solid #999;
          width: 50%;
          height: 40px;
          line-height: 40px;
          border-left: none;
          box-sizing: border-box;
          font-size: 12px;
          text-align: center;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        .active-show {
          background: #409eff;
          color: #fff;
          border: none;
        }
      }
    }

    .placeholder {
      color: #999;
      font-size: 24px;
      margin-right: 20px;
    }
  }

  > main {
    padding: 30px;
    margin-bottom: 20px;
    background-color: #fff;

    .title-wrap {
      padding-bottom: 15px;
      border-bottom: 2px solid @border-color;

      input {
        font-size: 32px;
      }
    }

    .content-wrap {
      position: relative;
      width: 100%;
      height: calc(~"100% - 270px");
      padding-right: 20px;
      margin-top: 15px;
      .textarea-shadow {
        opacity: 0;
        min-height: 400px;
        padding: 28px 0;
        word-wrap: break-word;
        word-break: break-all;
      }

      textarea {
        position: absolute;
        top: 0;
        display: block;
        font-size: 30px;
        // padding: 28px 0;
        width: calc(~"100% - 20px");
        height: 400px;
        overflow: auto;
        resize: none;
        outline: none;
        min-height: 400px;
        background-color: transparent;
        word-wrap: break-word;
        word-break: break-all;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 100%;
      font-size: 30px;
    }
  }
}

.mt20 {
  margin-top: 20px;
}

.arrow-bottom {
  transform: rotate(90deg);
  transition: all 0.3s linear;

  &.active {
    transform: rotate(270deg);
  }
}

.collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s linear;

  &.active {
    height: auto;
  }
}
.switch {
  width: 1rem;
  height: 0.5rem;
  border-radius: 50px;
  border: 1px solid #ddd;
  background-color: #efefef;
  position: relative;
}
.switch-btn {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  border: 1px solid #efefef;
  background-color: #fff;
  position: absolute;
  top: -6px;
  left: -2px;
  transition: left 1 ease-in-out;
  -moz-transition: left 1 ease-in-out; /* Firefox 4 */
  -webkit-transition: left 1 ease-in-out; /* Safari and Chrome */
  -o-transition: left 1 ease-in-out; /* Opera */
}
.switch-bg {
  background-color: #00baff;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50px;
  transition: width 1 ease-in-out;
  -moz-transition: width 1 ease-in-out; /* Firefox 4 */
  -webkit-transition: width 1 ease-in-out; /* Safari and Chrome */
}
.switch-btn.on {
  left: 0.5rem;
}
.switch-bg.on {
  width: 1rem;
}
</style>
