<template>
  <Transition name="popr">
    <div v-if="visible" class="publish-time-box">
      <CommonHeader :back="closePublish" head_title="选择发布时间">
        <span
          slot="right"
          :class="{ disabled }"
          class="check-vote"
          @click="choosePublish"
        >
          确认
        </span>
      </CommonHeader>
      <div class="publis-time-con">
        <main>
          <div class="radio-wrap">
            <label class="m-form-row m-main caladar-right">
              <span>立即发布</span>
              <input
                v-model="type"
                name="cur_education_1"
                type="radio"
                class="input-checkbox"
                :value="1"
              >
            </label>
            <label class="m-form-row m-main caladar-right">
              <span>限时发布</span>
              <input
                v-model="type"
                name="cur_education_1"
                type="radio"
                class="input-checkbox"
                :value="2"
              >
            </label>
          </div>
          <div v-if="type == 2" class="p-flex">
            <FormCalendar
              v-model="date"
              _name="date"
              label="年月日"
              placeholder="选择年月日"
              type="date"
              :min-date="nowDate"
              @click="setDate"
            />
            <FormCalendar
              v-if="date == nowDate"
              v-model="time"
              default-class="caladar-right"
              _name="time"
              label="时分"
              placeholder="选择时分"
              type="time"
              :min-time="nowTime"
              @click="setTime"
            />
            <FormCalendar
              v-else
              v-model="time"
              default-class="caladar-right"
              _name="time"
              label="时分"
              placeholder="选择时分"
              type="time"
              @click="setTime"
            />
          </div>
        </main>
      </div>
    </div>
  </Transition>
</template>

<script>
  import { mapGetters } from 'vuex'
  import FormCalendar from './FormCalendar.vue'

  export default {
    name: 'ChoosePublish',
    components: { FormCalendar },
    props: {
      publish: { type: Number, required: false, default: 1 },
      publish_time: { type: String, required: false, default: null },
    },
    data () {
      return {
        visible: false,
        type: 1,
        date: null,
        time: null,
      }
    },
    computed: {
      nowDate () {
        return new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
      },
      nowTime () {
        return new Date().getHours() + '-' + (new Date().getMinutes()) + '-' + new Date().getSeconds()
      },
      disabled () {
        if (this.type == 2 && (!this.date || !this.time)) {
          return true
        }
        return false
      },
    },
    created () {
      this.type = this.publish
      if (this.publish_time != null) {
        const time = this.publish_time.split(' ')
        this.date = time[0]
        this.time = time[1]
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      setDate (time) {
        this.date = time
      },
      setTime (time) {
        this.time = time
      },
      choosePublish () {
        let publish_time = null

        if (this.type == 2) {
          if (!this.date) {
            this.$Message.info('请选择发布日期')
            return
          }
          if (!this.time) {
            this.$Message.info('请选择发布具体时间')
            return
          }
          publish_time = this.date + ' ' + this.time + ':00'
          if (new Date(publish_time) < new Date()) {
            this.$Message.info('限时发布时间必须大于目前时间')
            return
          }
        }
        this.visible = false
        this.$emit('onChoosePublish', this.type, publish_time)
      },
      closePublish () {
        let publish_time = null

        if (this.type == 2) {
          if (!this.date || !this.time) {
            this.type = 1
          } else {
            publish_time = this.date + ' ' + this.time + ':00'
            if (new Date(publish_time) < new Date()) {
              this.$Message.info('限时发布时间必须大于目前时间')
              return
            }
          }
        }
        this.visible = false
        this.$emit('onChoosePublish', this.type, publish_time)
      },
    },
  }
</script>

<style lang="less" scoped>
.publish-time-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  background-color: #F5F5F5;

  .check-vote {
    color: @primary;
  }

  .check-vote.disabled {
    color: #ccc;
  }

  .publis-time-con {
    height: 100%;

    > main {
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: #fff;

      .m-form-row {
        height: 100px;
        padding: 0 30px;
        line-height: 100px;
      }

      .radio-wrap {
        > label {
          display: flex;

          input[type=radio] {
            appearance: none;
            -moz-appearance: none;
            -ms-progress-appearance: none;
            -webkit-appearance: none;
            display: inline-block;
            margin-left: auto;
            width: 36px;
            height: 36px;
            background: url("~@/images/ico_radio@2x.png") no-repeat center;
            background-size: cover;
            vertical-align: middle;
            border-radius: 50%;

            &:checked {
              background-image: url("~@/images/ico_radio_on@2x.png");
            }
          }
        }
      }
    }
  }
}
</style>
