<template>
  <div
    :class="{ 'show-slide': showSlide }"
    class="p-group-detail"
    @mousedown="startDrag"
    @touchstart="startDrag"
    @mousemove.stop="onDrag"
    @touchmove.stop="onDrag"
    @mouseup="stopDrag"
    @touchend="stopDrag"
    @mouseleave="stopDrag"
  >
    <header
      ref="head"
      class="m-box m-lim-width m-pos-f m-head-top bg-transp show-title"
    >
      <div class="m-box m-flex-grow1 m-aln-center m-flex-base0">
        <div class="left" @click="goBack">
          <img
            :src="require(`@/images/back_${scrollTop > (1 / 2) ? 'grey' : 'grey'}@2x.png`)"
            class="m-style-svg m-svg-def ico-back"
          >
        </div>
        <CircleLoading v-show="updating" color="light" />
      </div>
      <div class="m-box m-flex-grow1 m-aln-center m-flex-base0 m-justify-center">
        版块详情
      </div>
      <div class="m-box m-flex-grow1 m-aln-center m-flex-base0 m-justify-end">
        <img
          :src="require(`@/images/search_${scrollTop > (1 / 2) ? 'grey' : 'grey'}@2x.png`)"
          class="m-style-svg m-svg-def ico-search"
          @click="onSearchClick"
        >
        <!-- <svg class="m-style-svg m-svg-def" @click="onMoreClick">
          <use xlink:href="#icon-more" />
        </svg> -->
      </div>
    </header>
    <div v-if="loading" class="m-pos-f m-spinner" />

    <main style=" min-height: 100vh; position: relative; margin-top:0.9rem;">
      <div
        ref="banner"
        :style="[
          groupBackGround,
          paddingTop,
          { transitionDuration: dragging ? '0s' : '300ms' }
        ]"
        class="p-group-detail-banner"
      >
        <div class=" m-aln-start m-justify-st m-pos-f p-group-detail-bg-mask">
          <div class="p-group-detail-title">
            <img :src="avatar" class="logo">
            <div class="content">
              <div class="title">
                {{ group.name }}
                <img
                  v-if="lock"
                  :src="require('@/images/label_lock@2x.png')"
                  class="title-lock"
                >
              </div>
              <p>{{ groupPostsCount | t("group.post.count") }}</p>
            </div>
          </div>
          <div ref="summaryBox" class="desc text-cut-3">
            {{ summary }}
          </div>
        </div>
      </div>
      <div class="navBox">
        <nav
          id="content-switch-bar"
          class="type-switch-bar"
          :class="{ 'switch-bar-bar-before': contentBarBefore, 'switch-bar-bar-after': contentBarAfter }"
          @scroll="contentNavScrollEvent"
        >
          <span
            :class="{ active: tag_content === 0 }"
            @click="tag_content = 0"
          >
            全部
          </span>
          <span
            v-for="tag in tag_contents"
            :key="tag.id"
            :class="{ active: tag_content == tag.id }"
            @click="tag_content = tag.id"
          >
            {{ tag.name }}
          </span>
        </nav>
        <nav
          id="type-switch-bar"
          class="type-switch-bar type-switch-bars"
          :class="{ 'switch-bar-bar-before': typeBarBefore, 'switch-bar-bar-after': typeBarAfter }"
          @scroll="typeNavScrollEvent"
        >
          <span
            :class="{ active: feedType === 1 }"
            @click="feedType = 1"
          >
            {{ $t("newest") }}
          </span>
          <span
            :class="{ active: feedType === 2 }"
            @click="feedType = 2"
          >
            {{ $t("hot") }}
          </span>
        </nav>
      </div>
      <ul class="p-group-detail-feeds">
        <li
          v-for="card in pinneds"
          :key="`gdf-${groupId}-pinned-feed-${card.id}`"
        >
          <PostCard
            v-if="card.user_id"
            :post="card"
            :pinned="true"
          />
        </li>
        <li
          v-for="(card, index) in posts"
          :key="`gdf-${groupId}-feed-${card.id}-${index}`"
        >
          <PostCard v-if="card.user_id" :post="card" />
        </li>
      </ul>
      <div class="m-box m-aln-center m-justify-center load-more-box">
        <span v-if="noMoreData" class="load-more-ph">{{
          $t("loadmore.bottom[1]")
        }}</span>
        <span
          v-else
          class="load-more-btn"
          @click.stop="getFeeds(true)"
        >
          {{ fetchFeeding ? "loadmore.bottom[0]" : "loadmore.bottom[2]" | t }}
        </span>
      </div>
    </main>

    <aside class="slide-more">
      <ul class="list">
        <li @click="$router.push({ name: 'groupMembers' })">
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-group-members" />
            </svg>
            {{ $t("group.member") }}
          </span>
          <span>
            {{ group.users_count - group.blacklist_count }}
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </span>
        </li>
        <li @click="$router.push({ name: 'groupInfo' })">
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-group-info" />
            </svg>
            {{ $t("group.information") }}
          </span>
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </span>
        </li>
        <li v-if="isOwner" @click="$router.push({ name: 'groupPermission' })">
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-group-access" />
            </svg>
            {{ $t("group.post.permission") }}
          </span>
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </span>
        </li>
        <li
          v-if="isGroupManager"
          @click="$router.push({ name: 'groupBlackList' })"
        >
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-group-blacklist" />
            </svg>
            {{ $t("group.black") }}
          </span>
          <span>
            {{ group.blacklist_count }}
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </span>
        </li>
        <li v-else @click="onReport">
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-group-report" />
            </svg>
            {{ $t("group.report") }}
          </span>
          <span>
            <svg class="m-style-svg m-svg-small">
              <use xlink:href="#icon-arrow-right" />
            </svg>
          </span>
        </li>
      </ul>
      <button
        v-if="isOwner"
        class="btn-quit"
        @click="onTransfer"
      >
        {{ $t("group.transfer") }}
      </button>
      <button
        v-else
        class="btn-quit"
        @click="onExit"
      >
        {{ $t("group.exit") }}
      </button>
    </aside>
    <div class="slide-mask" @click="showSlide = false" />
    <img
      v-if="group.permissions"
      :src="require('@/images/post@2x.png')"
      class="create-post"
      @click="onCreatePostClick"
    >
  </div>
</template>

<script>
  import _ from 'lodash'
  import PostCard from '../components/PostCard.vue'
  import wechatShare from '@/util/wechatShare.js'
  import { getGroudFeedsByList } from '@/api/group.js'
  import { markdownText } from '@/filters.js'
  import { limit } from '@/api'

  export default {
    name: 'GroupDetail',
    directives: {
      clickoutside: {
        bind (el, binding) {
          function documentHandler (e) {
            if (el.contains(e.target)) {
              return false
            }
            if (binding.expression) {
              binding.value(e)
            }
          }
          el.__vueClickOutside__ = documentHandler
          document.addEventListener('click', documentHandler)
        },
        unbind (el) {
          document.removeEventListener('click', el.__vueClickOutside__)
          delete el.__vueClickOutside__
        },
      },
    },
    components: {
      PostCard,
    },
    data () {
      return {
        preGID: 0,

        scrollTop: 0,
        bannerHeight: 0,
        loading: true,
        dY: 0,
        startY: 0,
        dragging: false,
        updating: false,

        summaryHeight: 0,

        typeFilter: null,
        showFilter: false,

        feedTypes: {
          latest_post: this.$t('group.post.new'),
          latest_reply: this.$t('group.post.new_reply'),
        },

        noMoreData: false,
        fetchFeeding: false,

        posts: [],
        pinneds: [],
        showSlide: false,
        feedType: 1,

        tag_contents: [],
        tag_content: 0,

        contentBarBefore: false,
        contentBarAfter: false,
        typeBarBefore: false,
        typeBarAfter: false,
      }
    },
    computed: {
      groupId () {
        return this.$route.params.groupId
      },
      group () {
        return this.$store.state.group.current
      },
      currentUser () {
        return this.$store.state.CURRENTUSER
      },
      groupOwner () {
        return (this.group.founder || { user: {} }).user
      },
      groupUserCount () {
        return ~~this.group.users_count
      },
      groupPostsCount () {
        return ~~this.group.posts_count
      },
      summary () {
        const summary = this.group.summary || '暂无简介'
        return summary
      },
      groupAvatar () {
        if (this.group.bg) {
          return this.group.bg.url
        }
        return require('@/images/group_bg@2x.png')
        const avatar = this.group.avatar || {}
        return avatar.url || null
      },
      groupBackGround () {
        return {
          'background-image': this.groupAvatar ? `url("${this.groupAvatar}")` : '',
        }
      },
      location () {
        const location = this.group.location
        if (location) return location
        const map = this.$t('group.create.location_map')
        const rand = Math.floor(Math.random() * map.length)
        return map[rand]
      },

      // banner 相关
      paddingTop () {
        return {
          paddingTop: `calc(30% + ${this.summaryHeight + 10}px)`,
        }

        return {
          paddingTop:
            ((this.bannerHeight + 80 * Math.atan(this.dY / 200)) /
              (this.bannerHeight * 2)) *
            100 +
            '%',
        }
      },
      role () {
        if (!this.joined) return false
        return this.joined.role
      },
      permissions () {
        return this.group.permissions.split(',')
      },
      isOwner () {
        return this.groupOwner.id === this.currentUser.id
      },
      isManager () {
        return this.currentUser.permission.is_admin || false
      },
      isGroupManager () {
        const { role = '' } = this.group.joined || {}
        return ['founder', 'administrator'].includes(role)
      },
      isWechat () {
        return this.$store.state.BROWSER.isWechat
      },
      avatar () {
        if (this.group.avatar) {
          return this.group.avatar.url
        }
        if (this.groupId < 5) {
          return require(`@/images/section_${this.groupId}@2x.png`)
        }
        return null
      },
      lock () {
        return this.group.lock || null
      },
      publish () {
        return this.currentUser.permission.publish_video || false
      },
    },
    watch: {
      $route (to, from) {
        if (from.name === 'groupDetail') return
        this.showSlide = from.meta.sidebar
      },
      feedType () {
        this.posts = this.pinneds = []
        this.noMoreData = false
        this.getFeeds()
      },
      group (group) {
        console.log("group",group)
        if (group.id > 0 && this.isWechat) {
          document.title = group.name.length > 10 ? `${group.name.substr(0, 10)}...` : group.name
          const shareUrl =
            window.location.origin +
            process.env.BASE_URL.substr(0, process.env.BASE_URL.length - 1) +
            this.$route.fullPath
          const signUrl =
            this.$store.state.BROWSER.OS === 'IOS' ? window.initUrl : shareUrl
          let desc = markdownText(group.summary) || ''
          desc = desc.length > 20 ? `${desc.substr(0, 20)}...` : desc
          wechatShare(signUrl, {
            title: group.name,
            desc: desc,
            link: shareUrl,
            imgUrl: `${process.env.VUE_APP_API_HOST}/${this.avatar}`,
          })
        }
      },
      tag_content () {
        this.posts = this.pinneds = []
        this.getFeeds()
      },
    },
    created () {
      this.fetchTags()
    },
    mounted () {
      this.typeFilter = this.$refs.typeFilter
      this.bannerHeight = this.$refs.banner.getBoundingClientRect().height
      this.summaryHeight = this.$refs.summaryBox.offsetHeight

    // this.$matomoTrackPageView(`${this.group.name} - 旭辉员工论坛（SPA）`)
    },
    activated () {
      if (parseInt(this.groupId) !== this.group.id) {
        this.loading = true
        this.pinneds = []
        this.posts = []
      }
      this.updateData()
      window.addEventListener('scroll', this.onScroll)
    },
    deactivated () {
      this.showFilter = false
      window.removeEventListener('scroll', this.onScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
      fetchTags () {
        this.$http.get('/tags').then(({ data }) => {
          this.tag_contents = data[0].tags

          this.initNavScrollEvent()
        })
      },
      hidenFilter () {
        this.showFilter = false
      },
      getFeeds (more = false) {
        if (this.fetchFeeding) return
        this.fetchFeeding = true
        const offset = more ? this.pinneds.length + this.posts.length : 0
        const params = {
          type: this.feedType,
          limit: limit,
          offset: offset,
          group_id: this.groupId,
        }
        this.tag_content > 0 ? params.tag_content = this.tag_content : ''
        getGroudFeedsByList(this.groupId, params).then(
          ({ pinneds = [], posts = [] }) => {
            this.posts = more ? [...this.posts, ...posts] : posts
            this.pinneds = more ? [...this.pinneds, ...pinneds] : pinneds

            this.fetchFeeding = false
            this.noMoreData = posts.length < limit
          },
        )
      },
      async updateData () {
        this.dY = 0
        this.updating = true
        this.getFeeds()
        await this.$store.dispatch('group/getGroupById', {
          groupId: this.groupId,
        })
        this.updating = this.loading = false
        this.summaryHeight = this.$refs.summaryBox.offsetHeight
      },
      onScroll: _.debounce(function () {
        // this.scrollTop = Math.max(
        //   0,
        //   document.body.scrollTop,
        //   document.documentElement.scrollTop
        // );

        const _this = this
        window.onscroll = function () {
          // 变量scrollTop是滚动条滚动时，距离顶部的距离
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          // 变量windowHeight是可视区的高度
          const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
          // 变量scrollHeight是滚动条的总高度
          const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
          // 滚动条到底部的条件
          if (scrollTop + windowHeight + 300 >= scrollHeight) {
            // 到了这个就可以进行业务逻辑加载后台数据了
            if (!_this.noMoreData) {
              _this.getFeeds(true)
            }
          }
        }
      }, 1000 / 60),
      startDrag (e) {
        e = e.changedTouches ? e.changedTouches[0] : e
        if (this.scrollTop <= 0 && !this.updating) {
          this.startY = e.pageY
          this.dragging = true
        }
      },
      onDrag (e) {
        const $e = e.changedTouches ? e.changedTouches[0] : e
        if (this.dragging && $e.pageY - this.startY > 0 && window.scrollY <= 0) {
          // 阻止 原生滚动 事件
          e.preventDefault()
          this.dY = $e.pageY - this.startY
        }
      },
      stopDrag () {
        this.dragging = false
        this.dY > 300 && this.scrollTop <= 0 ? this.updateData() : (this.dY = 0)
      },
      onSearchClick () {
        this.$router.push({
          name: 'groupSearchPost',
          params: { groupId: this.groupId },
        })
      },
      onMoreClick () {
        this.showSlide = !this.showSlide
      },
      onCreatePostClick () {
        if (this.group.lock) {
          this.$Message.error('该版块已被锁，无法发帖')
          return
        }

        if (!this.publish) {
          return this.$router.push({
            name: 'groupCreatePost',
            query: {group: this.groupId},
          })
        }
        const actions = [{
          text: '图文',
          method: () => {
            this.$router.push({
              name: 'groupCreatePost',
              query: {group: this.groupId},
            })
          },
        }, {
          text: '视频',
          method: () => {
            this.$router.push({
              name: 'groupCreatePost',
              query: {group: this.groupId, type: 'video'},
            })
          },
        }]

        this.$bus.$emit('actionSheet', actions)
      },
      onExit () {
        const actions = [
          {
            text: this.$t('group.exit'),
            method: () => {
              this.exitGroup()
            },
            style: { color: 'red' },
          },
        ]
        this.$bus.$emit(
          'actionSheet',
          actions,
          this.$t('cancel'),
          this.$t('group.exit_confirm'),
        )
      },
      async exitGroup () {
        await this.$store.dispatch('group/exitGroup', { groupId: this.groupId })
        this.$Message.success(this.$t('group.exit_success'))
        this.$router.replace({ name: 'groupHome' })
      },
      onTransfer () {
        this.$router.push({ name: 'groupTransfer' })
      },
      onReport () {
        this.$bus.$emit('report', {
          type: 'group',
          payload: this.groupId,
          username: this.groupOwner.name,
          reference: this.group.name,
          callback: () => {
            this.showSlide = false
          },
        })
      },
      initNavScrollEvent () {
        setTimeout(() => {
          const contentEvent = this.$el.querySelector('#content-switch-bar')
          if (contentEvent.scrollWidth - contentEvent.scrollLeft - 10 > contentEvent.offsetWidth) {
            this.contentBarAfter = true
          }
        }, 300)
      },
      contentNavScrollEvent (event) {
        if (event.target.scrollLeft > 10) {
          this.contentBarBefore = true
        } else {
          this.contentBarBefore = false
        }
        if (event.target.scrollWidth - event.target.scrollLeft - 10 > event.target.offsetWidth) {
          this.contentBarAfter = true
        } else {
          this.contentBarAfter = false
        }
      },
      typeNavScrollEvent (event) {
        if (event.target.scrollLeft > 10) {
          this.typeBarBefore = true
        } else {
          this.typeBarBefore = false
        }
        if (event.target.scrollWidth - event.target.scrollLeft - 10 > event.target.offsetWidth) {
          this.typeBarAfter = true
        } else {
          this.typeBarAfter = false
        }
      },
    },
  }
</script>

<style lang="less" scoped>
.p-group-detail {
  position: relative;
  transition: 0.4s;
  &-title {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(229,229,229,0.3);
    .logo {
      width: 122px;
      height: 122px;
      border-radius: 50%;
      margin-right: 30px;
    }
    .content {
      color: #f4f4f4;
      .title {
        font-size: 32px;
        &-lock {
          width: 28px;
          height: 28px;
          margin-left: 10px;
        }
      }
      p {
        font-size: 24px;
      }
    }
  }
  .m-head-top {
    padding: 0 20px;

    .left {
      min-width: 0.9rem;
      padding: 0 0.5em;
      margin-left: -0.2rem;
      height: 0.9rem;
      line-height: 0.9rem;
    }

    .ico-search {
      width: 35px;
      height: 34px;
    }
  }

  > header {
    border-bottom: 0;
    transition: all 0.4s ease;

    &.bg-transp {
      color: #fff;
      background-color: transparent;
    }
    &.show-title {
      background-image: none;
      background-color: #fff;
      border-bottom: 1px solid @border-color; /*no*/
      color: #000;
      .m-trans-y {
        transform: none;
      }
    }
    .m-trans-y {
      transform: translateY(100%);
      transition: transform 0.3s ease;
    }
  }

  .slide-more {
    position: fixed;
    right: -400px;
    top: 0;
    bottom: 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #363845;
    color: #ccc;
    transition: 0.4s;
    z-index: 11;
    padding: 40px;

    .list {
      margin-top: 120px;
      border-top: 1px solid #4a4d5e; /* no */

      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        border-bottom: 1px solid #4a4d5e; /* no */
        font-size: 30px;

        svg {
          vertical-align: sub;
          color: #4a4d5e;
        }
      }
    }

    .btn-quit {
      width: 100%;
      border-radius: 8px;
      height: 60px;
      background-color: transparent;
      color: #ccc;
      border: 1px solid #4a4d5e; /* no */
      font-size: 30px;
    }
  }
  .slide-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;
  }

  &.show-slide {
    margin-left: -400px;
    padding-right: 400px;

    > header {
      margin-left: -400px;
    }

    .slide-more {
      right: 0;
    }
    .slide-mask {
      display: block;
    }
  }

  .address {
    display: inline-block;
    max-width: 20em;
  }

  .create-post {
    position: fixed;
    bottom: 40px;
    height: 80px;
    width: 80px;
    right: 70px;
    padding: 0;
    border-radius: 100%;
    margin-left: -30px;
    background-color: @primary;
    // border: 2px solid #fff;
    // box-shadow: 0px 0px 12px 0px rgba(89, 182, 215, 0.43);
    z-index: 1;

    > svg {
      display: block;
      margin: auto;
    }
  }
}
.p-group-detail-banner {
  // overflow-x: hidden;
  padding-top: 320/640 * 100%;
  width: 100%;
  transform: translate3d(0, 0, 0);
  background-size: cover;
  background-position: center;
  font-size: 28px;
  color: #fff;
  // text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5); /* no */

  h3 {
    font-size: 32px;
  }
  p {
    span + span {
      margin-left: 40px;
    }
    i {
      margin: 0 5px;
    }
  }

  .p-group-detail-avatar {
    margin-right: 20px;
    overflow: hidden;
    width: 140px;
    height: 140px;
    border: 2px solid #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  //背景虚化
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    margin: auto;

    filter: blur(15px);
    // transform: scale(1.1);
    background: inherit;
  }
}

.p-group-detail-bg-mask {
  padding: 88px 30px 0;
  background-color: rgba(124, 124, 124, 0.2);
}

.p-group-detail-info {
  background-color: #fff;
  padding: 30px 20px;
  font-size: 26px;
  line-height: 36px;
  color: @text-color3;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /*no*/
  p + p {
    margin-top: 10px;
  }
}

.p-group-detail-tag {
  margin-top: 20px;
  margin-left: 10px;
  display: inline-block;
  padding: 5px 20px;
  font-size: 24px;
  background-color: rgba(102, 102, 102, 0.1);
  border-radius: 18px;
}
.p-group-detail-filter {
  position: relative;
  &-box {
    padding: 25px 20px;
    color: @text-color3;
    font-size: 26px;
    position: sticky;
    top: 88px;
    z-index: 9;
    background-color: #f4f5f6;
    .m-style-svg {
      margin-left: 20px;
    }
  }
  &-options {
    overflow: hidden;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 9;
    min-width: 200px;
    border-radius: 8px;
    background-color: #fff;
    transform: translate3d(0, 25px, 0);
    box-shadow: 0 0 10px 0 rgba(221, 221, 221, 0.6); /*no*/
    li {
      padding: 25px 20px;
      font-size: 24px;
      color: @text-color3;
      & + li {
        border-top: 1px solid @border-color; /*no*/
      }
    }
  }
}

.p-group-detail-feeds {
  li + li {
    margin-top: 20px;
  }
}

.group-item-action {
  margin-bottom: 80px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 126px;
    height: 50px;

    border: 1px solid currentColor; /*no*/
    border-radius: 8px;
    background-color: #fff;

    font-size: 24px;
    color: @primary;
    transition: all 0.3s ease;
    span {
      transition: all 0.3s ease;
      margin-left: 5px;
    }
    &[disabled] {
      color: @border-color;
      cursor: not-allowed;
      span {
        color: @text-color3;
      }
      svg {
        opacity: 0.5;
      }
    }
  }

  &.c_fff {
    button {
      background-color: transparent;
      color: #fff;
    }
  }
}

.white {
  color: #fff;
}
.desc {
  padding: 30px 0;
  color: #f4f4f4;
  font-size: 24px;
}
.navBox {
  position: sticky;
  top: 90px;
  z-index: 2;
}
.type-switch-bar {
  justify-content: flex-start;
  background-color: #fff;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 87px;
  color: #333;
  font-size: 28px;
  border-bottom: 1px solid #e5e5e5;
  display: block;
  // touch-action: none;
}
.type-switch-bar::-webkit-scrollbar {
  display: none;
}
.switch-bar-bar-before::before {
  content: "";
  position: fixed;
  left: 0;
  background: linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,0.1) 100%);
  opacity: 1;
  width: 1rem;
  z-index: 1;
  height: 95px;
}
.switch-bar-bar-after::after {
  content: "";
  position: fixed;
  right: 0;
  background: linear-gradient(90deg,rgba(255,255,255,0.1) 0%,rgba(255,255,255,1) 100%);
  opacity: 1;
  width: 1rem;
  z-index: inherit;
  height: 95px;
}

.type-switch-bars {
  color: #999;
  font-size: 26px;
  border-bottom: 1px solid transparent;
}
.type-switch-bar > span {
  width: auto;
  margin: 0 20px;
  color: #333333;
  transition: unset;
}
.type-switch-bar > span.active {
  font-size: 32px;
  color: @primary;
  font-weight: 500;
}
.type-switch-bars > span.active {
  font-size: 26px;
  color: #1d1d1d;
  font-weight: 500;
}
.type-switch-bar > span.active::after {
  background-color: transparent;
}
</style>
